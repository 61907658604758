import { useEffect, useState } from "react";
import "./Vault.css";
import { formatDateTime, SECONDS_PER_HOUR, formatSecondsToDaysString } from "lib/dates";
import { Trans } from "@lingui/macro";

export default function VaultGeneralInfo(props) {
  const { vaultData } = props;

  const {
    currentEpochFmt,
    currentEpochStartFmt,
    currentEpochEndFmt,
    remainingEpochTimeFmt,
    remainingEpochTime,
    tvlFmt,
    collatRatioFmt,
    dBUSDPriceFmt,
    dBUSDTotalSupplyFmt,
  } = vaultData;

  return (
    <div className="vault-info">
      <div className="info-eachpoch-vault">
        <div className="">
          <p>dUSDT Vault</p>
        </div>

        <div className="info-eachpoch">
          <div className="">
            <div className="eachpoch">
              <Trans>Epoch {currentEpochFmt}</Trans>
            </div>
          </div>

          {remainingEpochTime > 0 && (
            <div className="">
              <div className="" style={{ textAlign: "right" }}>
                <Trans>Remaining </Trans>: {remainingEpochTimeFmt}
              </div>
            </div>
          )}

          <div className="">
            <div className="" style={{ textAlign: "right" }}>
              <Trans>Start </Trans>: {currentEpochStartFmt}
            </div>
          </div>

          <div className=""  style={{ marginBottom: remainingEpochTime > 0 ? '-15px' : '0' }}>
            <div className="" style={{ textAlign: "right" }}>
              <Trans>End </Trans>: {currentEpochEndFmt}
            </div>
          </div>
        </div>
      </div>

      <div className="global-info">
        <div className="">
          <div className="">
            <Trans>TVL USDT</Trans>
          </div>
          <div className="green">{"$" + tvlFmt}</div>
        </div>
        <div className="">
          <div className="">
            <Trans>Collat Ratio</Trans>
          </div>
          <div className="green">{collatRatioFmt + "%"}</div>
        </div>
        <div className="">
          <div className="">
            <Trans>USDT Price</Trans>
          </div>

          <div className="green">{dBUSDPriceFmt}</div>
        </div>
        <div className="">
          <div className="">
            <Trans>USDT Supply</Trans>
          </div>
          <div className="green">{dBUSDTotalSupplyFmt}</div>
        </div>
      </div>
    </div>
  );
}
