import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import ReactGA from "react-ga4";
import { SWRConfig } from "swr";
import { ethers } from "ethers";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import useScrollToTop from "lib/useScrollToTop";

import AppContext from "AppContext";
import { Link, Switch, Route, BrowserRouter as Router, Redirect, useLocation, useHistory } from "react-router-dom";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  BASIS_POINTS_DIVISOR,
  getAppBaseUrl,
  isHomeSite,
  isMobileDevice,
  REFERRAL_ADDRESS_PARAM,
  REFERRER_CODE_PARAM,
  isDevelopment,
  isAddressZero,
} from "lib/legacy";
import { IS_PROD, MODE, GA_MEASURE_ID } from "config/context";

import Home from "pages/Home/Home";
import Dashboard from "pages/Dashboard/Dashboard";
import Ecosystem from "pages/Ecosystem/Ecosystem";
import Stake from "pages/Stake/Stake";
import { Exchange } from "pages/Exchange/Exchange";
import { Otc } from "pages/Otc/Otc";
import { Vault } from "pages/Vault/Vault";
import Actions from "pages/Actions/Actions";
import OrdersOverview from "pages/OrdersOverview/OrdersOverview";
import PositionsOverview from "pages/PositionsOverview/PositionsOverview";
import Referrals from "pages/Referrals/Referrals";
import BuyGlp from "pages/BuyGlp/BuyGlp";
import BuyGMX from "pages/BuyGMX/BuyGMX";
import Buy from "pages/Buy/Buy";
import NftWallet from "pages/NftWallet/NftWallet";
import ClaimEsGmx from "pages/ClaimEsGmx/ClaimEsGmx";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import CompleteAccountTransfer from "pages/CompleteAccountTransfer/CompleteAccountTransfer";

import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import SpinningLoader from "components/Common/SpinningLoader";

import "styles/Shared.css";
import "styles/Font.css";
import "./App.scss";
import "./responsive.css";
import "styles/Input.css";

import metamaskImg from "img/metamask.png";
import coinbaseImg from "img/coinbaseWallet.png";
import walletConnectImg from "img/walletconnect-circle-blue.svg";
import useEventToast from "components/EventToast/useEventToast";
import EventToastContainer from "components/EventToast/EventToastContainer";
import SEO from "components/Common/SEO";
import useRouteQuery from "lib/useRouteQuery";
//import { encodeReferralCode, decodeReferralCode } from "domain/referrals";

import VaultV2 from "abis/VaultV2.json";
import VaultV2b from "abis/VaultV2b.json";
import PositionRouter from "abis/PositionRouter.json";
import { useParams } from "react-router-dom";

import PageNotFound from "pages/PageNotFound/PageNotFound";
import ReferralTerms from "pages/ReferralTerms/ReferralTerms";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import { useLocalStorage } from "react-use";
import { RedirectPopupModal } from "components/ModalViews/RedirectModal";
import { REDIRECT_POPUP_TIMESTAMP_KEY } from "config/ui";
import Jobs from "pages/Jobs/Jobs";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { defaultLocale, dynamicActivate } from "lib/i18n";
import { Header } from "components/Header/Header";
import { getExplorerUrl, isTestnetChainId, getChainKind, DEFAULT_CHAIN_ID, ALT_CHAIN_ID } from "config/chains";

import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";

import {
  SELECTED_NETWORK_LOCAL_STORAGE_KEY,
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
  REFERRER_ADDRESS,
  REFERRAL_CODE_KEY,
  TMP_REFERRAL_CODE_KEY,
} from "config/localStorage";
import {
  activateInjectedProvider,
  clearWalletConnectData,
  clearWalletLinkData,
  getInjectedHandler,
  getWalletConnectHandler,
  hasCoinBaseWalletExtension,
  hasMetaMaskWalletExtension,
  useEagerConnect,
  useInactiveListener,
} from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { FuckiesComponent } from "pages/Fuckies/FuckiesComponent";

import { waitForOpenConnection } from "lib/sockets";

import { setContract, getContract, WS_TO_FRONT_NAME_MAP, HARDCODED_TESTNET_ADDRESSES } from "config/contracts";
import { Stats } from "pages/Stats/Stats";
import { Banners } from "components/Exchange/Banners";
import { Leaderboard } from "pages/TradingCompetition/Leaderboard";
import { Round1 } from "pages/TradingCompetition/Round1";
import { Round2 } from "pages/TradingCompetition/Round2";
import { Round3 } from "pages/TradingCompetition/Round3";
import { LeaderboardLoyalty } from "pages/TradingCompetition/LeaderboardLoyalty";
const { AddressZero } = ethers.constants;

if ("ethereum" in window) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function getLibrary(provider) {
  const library = new Web3Provider(provider, "any");
  return library;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

/*
const arbWsProvider = new ethers.providers.WebSocketProvider(getAlchemyWsUrl());

const avaxWsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

function getWsProvider(active, chainId) {
  if (!active) {
    return;
  }
  if (chainId === ARBITRUM) {
    return arbWsProvider;
  }

  if (chainId === AVALANCHE) {
    return avaxWsProvider;
  }
}
*/

function FullApp() {
  const isHome = isHomeSite();
  const exchangeRef = useRef();
  const { connector, library, deactivate, activate, active, account } = useWeb3React();
  const { chainId } = useChainId();

  const { endpointsInfo, filteredEndpointsInfo } = useContext(AppContext);

  const location = useLocation();
  const history = useHistory();
  useEventToast();
  const [activatingConnector, setActivatingConnector] = useState();

  useEffect(() => {
    // Inicializa Google Analytics
    ReactGA.initialize(GA_MEASURE_ID);

    // Registra la primera carga de página
    IS_PROD || console.log("Sending GA pageview", history.location.pathname);
    ReactGA.send({ hitType: "pageview", page: history.location.pathname });

    // Escucha cambios en la ruta para registrar las vistas de página
    const unlisten = history.listen((location) => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    });

    // Limpieza del listener cuando el componente se desmonte
    return unlisten;
  }, [history]); // Asegúrate de agregar `history` como una dependencia de useEffect

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, chainId]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);

  const query = useRouteQuery();

  useEffect(() => {
    IS_PROD || console.log("**** REACT_APP_MODE", MODE);
    IS_PROD || console.log("**** chainId", chainId);
    IS_PROD || console.log("**** filteredEndpointsInfo", filteredEndpointsInfo);
    IS_PROD || console.log("**** endpointsInfo", endpointsInfo);

    const urlSearchParams = new URLSearchParams(window.location.hash.substr(1));
    const refValue = urlSearchParams.get("ref");

    if (refValue) {
      // Haz algo con el valor de 'ref', por ejemplo, guárdalo en una variable.
      IS_PROD || console.log("Valor de ref:", refValue);
    }
  }, []);

  useEffect(() => {
    // Check if the URL hash value is "/dashboard"
    if (window.location.hash === "#/dashboard") {
      // Replace the current entry in the history stack.
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  //const tempReferrer = useRef(null);

  /*
  const isValidReferrerCode = (referrer) => {
    if (!referrer) {
      return false;
    }
    return true;
    const isCode = referrer.length === 6;
    if (isCode) {
      return true;
    }
  };*/

  const setReferralIntoLocalStorage = (referrer, account) => {
    let _referrer;

    if (!account) {
      IS_PROD || console.log(">> no account", account, referrer, localStorage.getItem(TMP_REFERRAL_CODE_KEY));
      if (!referrer) return;
      _referrer = referrer ? referrer : AddressZero;
      if (!localStorage.getItem(TMP_REFERRAL_CODE_KEY)) {
        IS_PROD || console.log(">> set TMP_REFERRAL_CODE_KEY", _referrer);
        localStorage.setItem(TMP_REFERRAL_CODE_KEY, _referrer);
        IS_PROD || console.log(">> TMP_REFERRAL_CODE_KEY", localStorage.getItem(TMP_REFERRAL_CODE_KEY));
      }
      return;
    }
    const referrers = JSON.parse(localStorage.getItem(REFERRAL_CODE_KEY)) || {};
    const currentReferrer = referrers[account] || null;
    if (!currentReferrer) {
      const _tmpReferrer = localStorage.getItem(TMP_REFERRAL_CODE_KEY);
      IS_PROD || console.log(">> _tmpReferrer", _tmpReferrer);
      _referrer = _tmpReferrer ? _tmpReferrer : referrer;
      _referrer = _referrer !== null ? _referrer : AddressZero;
      referrers[account] = _referrer;
      localStorage.setItem(REFERRAL_CODE_KEY, JSON.stringify(referrers));
    }
    localStorage.removeItem(TMP_REFERRAL_CODE_KEY);
  };
  /*
  const setReferralIntoLocalStorageOld = (referrer, account) => {
    if (!account) {
      tempReferrer.current = referrer;
      IS_PROD || console.log(">> tempReferrer", tempReferrer.current);
      return;
    }
    //const isAddress = ethers.utils.isAddress(referrer);
    if (isValidReferrerCode(referrer)) {
      const referrers = JSON.parse(localStorage.getItem(REFERRAL_CODE_KEY)) || {};
      const currentReferrer = referrers[account] || null;
      if (!currentReferrer) {
        referrers[account] = referrer;
        localStorage.setItem(REFERRAL_CODE_KEY, JSON.stringify(referrers));
        //tempReferrer.current = null; // Clear the temp referrer
      }
    }
  };
  */

  useEffect(() => {
    let referrer = query.get(REFERRER_CODE_PARAM);
    IS_PROD || console.log(">> query.referrer", referrer);
    setReferralIntoLocalStorage(referrer, account);
  }, [query, account]);

  /*
  useEffect(() => {
    let referrer = query.get(REFERRER_CODE_PARAM);
    IS_PROD || console.log(">> query.referrer", referrer);
    //let realReferrer = referrer ? referrer : AddressZero;
    setReferralIntoLocalStorage(referrer, account);
  }, [query]);

  useEffect(() => {
    let _referrer = query.get(REFERRER_CODE_PARAM);
    IS_PROD || console.log(">> account.referrer", _referrer);
    const referrer = _referrer ? _referrer : tempReferrer.current;
    setReferralIntoLocalStorage(referrer, account);
  }, [account]);*/

  const disconnectAccount = useCallback(() => {
    // only works with WalletConnect
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    deactivate();
  }, [deactivate]);

  const disconnectAccountAndCloseSettings = () => {
    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const connectInjectedWallet = getInjectedHandler(activate);
  const activateWalletConnect = () => {
    getWalletConnectHandler(activate, deactivate, setActivatingConnector)();
  };

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = () => {
    if (!hasMetaMaskWalletExtension()) {
      helperToast.error(
        <div>
          <Trans>MetaMask not detected.</Trans>
          <br />
          <br />
          {userOnMobileDevice ? (
            <Trans>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use GMX with its built-in
              browser
            </Trans>
          ) : (
            <Trans>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using Dexynth.
            </Trans>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("MetaMask");
  };
  const activateCoinBase = () => {
    if (!hasCoinBaseWalletExtension()) {
      helperToast.error(
        <div>
          <Trans>Coinbase Wallet not detected.</Trans>
          <br />
          <br />
          {userOnMobileDevice ? (
            <Trans>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink>, and use GMX
              with its built-in browser
            </Trans>
          ) : (
            <Trans>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink> to start using
              GMX
            </Trans>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("CoinBase");
  };

  const attemptActivateWallet = (providerName) => {
    localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
    localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
    activateInjectedProvider(providerName);
    connectInjectedWallet();
  };

  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false);
  const [redirectPopupTimestamp, setRedirectPopupTimestamp, removeRedirectPopupTimestamp] =
    useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY);
  const [selectedToPage, setSelectedToPage] = useState("");
  const connectWallet = () => setWalletModalVisible(true);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );
  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );

  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount);
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting);
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(t`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(t`Slippage should be less than 5%`);
      return;
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(t`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  /*
  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const baseUrl = getAppBaseUrl();
  let appRedirectUrl = baseUrl + selectedToPage;
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode);
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`;
    }
  }
*/
  const [pendingTxns, setPendingTxns] = useState([]);

  const [contractsLoaded, setContractsLoaded] = useState(false);

  const showRedirectModal = (to) => {
    setRedirectModalVisible(true);
    setSelectedToPage(to);
  };

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await library.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <Trans>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </Trans>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <Trans>View</Trans>
                </ExternalLink>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [library, pendingTxns, chainId]);

  /*
  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  */

  /*
  useEffect(() => {
    const wsVaultAbi = chainId === ARBITRUM ? VaultV2.abi : VaultV2b.abi;
    const wsProvider = getWsProvider(active, chainId);
    if (!wsProvider) {
      return;
    }

    
    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);
    
    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [active, chainId , vaultAddress, positionRouterAddress]);
  */

  const [currentContract, setCurrentContract] = useState("");
  const eventSocket = useRef(null);

  const handleWsEvents = async (event) => {
    let object;
    //console.log("----", event.data, typeof event.data);
    const eventData = event.data;
    //const eventData = HARDCODED_TESTNET_ADDRESSES;

    try {
      object = JSON.parse(eventData);
    } catch (error) {
      console.error(error);
      return;
    }
    //console.log(object);
    if (!object[DEFAULT_CHAIN_ID] && !object[ALT_CHAIN_ID]) {
      return;
    }

    Object.keys(object).forEach((_chainId) => {
      Object.keys(object[_chainId]).forEach((contract) => {
        const frontContractName = WS_TO_FRONT_NAME_MAP[contract];
        const contractAddress = object[_chainId][contract] ? object[_chainId][contract] : AddressZero;
        setContract(_chainId, frontContractName, contractAddress);
        IS_PROD || console.log(`${_chainId}.${frontContractName} :  "${getContract(_chainId, frontContractName)}",`);
      });
    });

    setContractsLoaded(true);
    eventSocket.current.close();
    IS_PROD || console.log("Contracts addresses loaded from WS", eventSocket.current);
  };

  const setupWebsocket = async (_chainId) => {
    const url = filteredEndpointsInfo?.events_relayer;
    eventSocket.current = new WebSocket(url);

    eventSocket.current.onopen = async () => {
      await waitForOpenConnection(eventSocket.current);
      IS_PROD || console.log(`WebSocket connection to ${url} opened, trying get contracts addresses...`);
      IS_PROD || console.log("Sending message: ", `/getContractsAddresses`);
      eventSocket.current.send(`/getContractsAddresses`);
    };

    eventSocket.current.onmessage = handleWsEvents;

    eventSocket.current.onclose = () => {
      IS_PROD || console.log(`WebSocket connection to ${url} closed.`);
      eventSocket.current = null;
    };
  };
  useEffect(() => {
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, chainId);
    if (filteredEndpointsInfo?.events_relayer) setupWebsocket(chainId);
    //}
  }, [chainId, filteredEndpointsInfo]);

  const [hideBanner, setHideBanner] = useState(false);

  return (
    <>
      <div className="App">
        <div className="App-content">
          <Header
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            openSettings={openSettings}
            setWalletModalVisible={setWalletModalVisible}
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
            setContractsLoaded={setContractsLoaded}
            setCurrentContract={setCurrentContract}
          />
          <Banners chainId={chainId} path={location.pathname} />
        </div>

        {!contractsLoaded && (
          <div className="Loading">
            <center>
              {<SpinningLoader />}
              {/* <br /> */}
              {/* <br /> */}
              {/* <span>{!library ? "Please, connect your wallet!!" : "Loading contracts..."}</span> */}
              {/*currentContract*/}
            </center>
          </div>
        )}
        {contractsLoaded && (
          <div className="App-content">
            {isHome && (
              <Switch>
                <Route exact path="/">
                  <Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
                </Route>
                <Route exact path="/referral-terms">
                  <ReferralTerms />
                </Route>
                <Route exact path="/terms-and-conditions">
                  <TermsAndConditions />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            )}
            {!isHome && (
              <Switch>
                <Route exact path="/trading">
                  <Exchange
                    setWalletModalVisible={setWalletModalVisible}
                    ref={exchangeRef}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route exact path="/staking">
                  <Stake setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route exact path="/buy">
                  <Buy
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                  />
                </Route>
                <Route exact path="/trading-contest/round-1/leaderboard">
                  <Round1 />
                </Route>
                <Route exact path="/trading-contest/round-2/leaderboard">
                  <Round2 />
                </Route>
                <Route exact path="/trading-contest/round-3/leaderboard">
                  <Round3 />
                </Route>
                <Route exact path="/general-trading/leaderboard">
                  <Leaderboard />
                </Route>
                <Route exact path="/loyalty-program/leaderboard">
                  <LeaderboardLoyalty />
                </Route>
                <Route exact path="/otc">
                  <Otc setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route exact path="/vault">
                  <Vault setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route exact path="/buy_glp">
                  <BuyGlp
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
                <Route exact path="/jobs">
                  <Jobs />
                </Route>
                <Route exact path="/buy_gmx">
                  <BuyGMX />
                </Route>
                <Route exact path="/ecosystem">
                  <Ecosystem />
                </Route>
                <Route exact path="/loyalty-program">
                  <Referrals
                    setWalletModalVisible={setWalletModalVisible}
                    pendingTxns={pendingTxns}
                    connectWallet={connectWallet}
                    setPendingTxns={setPendingTxns}
                  />
                </Route>
                <Route exact path="/nft_wallet">
                  <NftWallet />
                </Route>
                <Route path="/fuckies">
                  <FuckiesComponent />
                </Route>
                <Route path="/stats">
                  <Stats />
                </Route>
                <Route exact path="/claim_es_gmx">
                  <ClaimEsGmx setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/actions">
                  <Actions />
                </Route>
                <Route exact path="/actions/:account">
                  <Actions />
                </Route>
                <Route exact path="/orders_overview">
                  <OrdersOverview />
                </Route>
                <Route exact path="/positions_overview">
                  <PositionsOverview />
                </Route>
                <Route exact path="/begin_account_transfer">
                  <BeginAccountTransfer setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/complete_account_transfer/:sender/:receiver">
                  <CompleteAccountTransfer setPendingTxns={setPendingTxns} />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
                <Redirect from="/#/dashboard" to="/" />
              </Switch>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
      <EventToastContainer />
      <RedirectPopupModal
        redirectModalVisible={redirectModalVisible}
        setRedirectModalVisible={setRedirectModalVisible}
        setRedirectPopupTimestamp={setRedirectPopupTimestamp}
        setShouldHideRedirectModal={setShouldHideRedirectModal}
        shouldHideRedirectModal={shouldHideRedirectModal}
        removeRedirectPopupTimestamp={removeRedirectPopupTimestamp}
      />
      <Modal
        className="Connect-wallet-modal"
        isVisible={walletModalVisible}
        setIsVisible={setWalletModalVisible}
        label={t`Connect Wallet`}
      >
        <button className="Wallet-btn MetaMask-btn" onClick={activateMetaMask}>
          <img src={metamaskImg} alt="MetaMask" />
          <div>
            <Trans>MetaMask</Trans>
          </div>
        </button>
        {/* <button className="Wallet-btn CoinbaseWallet-btn" onClick={activateCoinBase}>
          <img src={coinbaseImg} alt="Coinbase Wallet" />
          <div>
            <Trans>Coinbase Wallet</Trans>
          </div>
        </button>
        <button className="Wallet-btn WalletConnect-btn" onClick={activateWalletConnect}>
          <img src={walletConnectImg} alt="WalletConnect" />
          <div>
            <Trans>WalletConnect</Trans>
          </div>
        </button> */}
      </Modal>
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={t`Settings`}
      >
        <div className="App-settings-row">
          <div>
            <Trans>Allowed Slippage</Trans>
          </div>
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              value={slippageAmount}
              onChange={(e) => setSlippageAmount(e.target.value)}
            />
            <div className="App-slippage-tolerance-input-percent">%</div>
          </div>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
            <Trans>Display PnL after fees</Trans>
          </Checkbox>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
            <Trans>Include PnL in leverage display</Trans>
          </Checkbox>
        </div>
        {isDevelopment() && (
          <div className="Exchange-settings-row">
            <Checkbox isChecked={shouldDisableValidationForTesting} setIsChecked={setShouldDisableValidationForTesting}>
              <Trans>Disable order validations</Trans>
            </Checkbox>
          </div>
        )}

        <button className="App-cta Exchange-swap-button" onClick={saveAndCloseSettings}>
          <Trans>Save</Trans>
        </button>
      </Modal>
    </>
  );
}

function App() {
  useScrollToTop();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);
  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <SEO>
          <Router>
            <I18nProvider i18n={i18n}>
              <FullApp />
            </I18nProvider>
          </Router>
        </SEO>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

export default App;
