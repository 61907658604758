import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { select, t, Trans } from "@lingui/macro";
import Slider, { SliderTooltip } from "rc-slider";
import "./SwapBox.css";
import "rc-slider/assets/index.css";
import cx from "classnames";
import useSWR from "swr";
import { ethers } from "ethers";
import AppContext from "AppContext";
import { useContracts } from "hooks/useContracts";

import { PairsStorage } from "config/contracts";
import { IS_PROD, MODE } from "config/context";

// import { IoMdSwap } from "react-icons/io";
// import { BsArrowRight } from "react-icons/bs";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  TESTNET,
  SHARDEUM_TESTNET,
  getChainName,
  NETWORK_OPTIONS,
  ZKSYNC_TESTNET,
  OPBNB_TESTNET,
  ZETACHAIN_TESTNET,
  TEN_TESTNET,
  SHIMMER_MAINNET,
  IOTA_TESTNET,
  BERACHAIN_TESTNET,
  getConstant,
  IS_NETWORK_DISABLED,
  isSupportedChain,
  FIRE_CHAIN_TESTNET,
  FIRE_CHAIN_MAINNET,
} from "config/chains";

import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  getAccountUrl,
  getAppBaseUrlGen,
  getExchangeRate,
  getExchangeRateDisplay,
  getLeverage,
  getLiquidationPrice,
  getNextFromAmount,
  getNextToAmount,
  getPositionKey,
  isHomeSite,
  isTriggerRatioInverted,
  LEVERAGE_ORDER_OPTIONS,
  LIMIT,
  LONG,
  MARGIN_FEE_BASIS_POINTS,
  MARKET,
  PRECISION,
  SHORT,
  STOP,
  SWAP,
  SWAP_OPTIONS,
  SWAP_ORDER_OPTIONS,
  USD_DECIMALS,
  USDG_ADDRESS,
  USDG_DECIMALS,
} from "lib/legacy";

import * as Api from "domain/legacy";
import { getContract } from "config/contracts";

// import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
// import TokenSelector from "./TokenSelector";
import ExchangeInfoRow from "./ExchangeInfoRow";
import ConfirmationBox from "./ConfirmationBox";
import OrdersToa from "./OrdersToa";

import PositionRouter from "abis/PositionRouter.json";
import Router from "abis/Router.json";
import Token from "abis/Token.json";
import WETH from "abis/WETH.json";

import longImg from "img/long.svg";
import shortImg from "img/short.svg";
import swapImg from "img/swap.svg";

// import { useUserReferralCode } from "domain/referrals";
import { useLoyaltyReferrals } from "hooks/useLoyaltyReferrals.js";
import NoLiquidityErrorModal from "./NoLiquidityErrorModal";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import {
  approveTokens,
  getMostAbundantStableToken,
  replaceNativeTokenAddress,
  shouldRaiseGasError,
} from "domain/tokens";

import { REFERRER_ADDRESS, REFERRAL_CODE_KEY } from "config/localStorage";

import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue, formatNumber } from "lib/numbers";
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { BsConeStriped } from "react-icons/bs";

const SWAP_ICONS = {
  [LONG]: longImg,
  [SHORT]: shortImg,
  [SWAP]: swapImg,
};

const { AddressZero } = ethers.constants;

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

function getNextAveragePrice({ size, sizeDelta, hasProfit, delta, nextPrice, isLong }) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return;
  }
  const nextSize = size.add(sizeDelta);
  let divisor;
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta);
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta);
  }
  if (!divisor || divisor.eq(0)) {
    return;
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor);
  return nextAveragePrice;
}

export default function SwapBox(props) {
  const {
    leftWidth,
    overFlow,
    pairs,
    ourSelectedToken,
    pendingPositions,
    setPendingPositions,
    infoTokens,
    active,
    library,
    account,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    totalTokenWeights,
    usdgSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableValidationForTesting,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    tradingV6Contract,
    busdContract,
    GFarmTradingStorageV5_Testnet,
    setTakeProfit,
    takeProfit,
    setStopLoss,
    stopLoss,
    actualPrice,
    userBusdBalance,
    spread,
    onePercentDepthAbove,
    onePercentDepthBelow,
    minLeverage,
    maxLeverage,
    longOpenInterest,
    shortOpenInterest,
    maxOpenInterest,
    minLevPosDai,
    groupCollateralLong,
    groupCollateralShort,
    groupMaxCollateral,
    maxCollateralP,
    setWalletModalVisible,
    tvl,
  } = props;

  const { pares, latestPrices, market24 } = useContext(AppContext);
  const { contractInfo } = useContracts({ chainId });

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("100");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isApproved, setisApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const [slippageAmount, setSlippageAmount] = useState(1);
  const [personalizedTokenPrice, setPersonalizedTokenPrice] = useState("");
  const [allowChangePersonalizedTokenPrice, setAllowChangePersonalizedTokenPrice] = useState(true);
  const [manualStopLoss, setManualStopLoss] = useState(false);
  const [manualTakeProfit, setManualTakeProfit] = useState(false);
  const [takeProfitValue, setTakeProfitValue] = useState(0);
  const [stopLossValue, setStopLossValue] = useState(0);
  const [minTP, setMinTP] = useState(0);
  const [maxTP, setMaxTP] = useState(0);
  const [minSL, setMinSL] = useState(0);
  const [maxSL, setMaxSL] = useState(0);
  const [pairGroup, setPairGroup] = useState(0);
  const [openingFees, setOpeningFees] = useState(0.14);
  const [positionSize, setPositionSize] = useState(0);

  const [marketReadOnly, setmarketReadOnly] = useState("readOnly");
  // const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  useEffect(() => {
    let sl = localStorage.getItem("StopLoss");
    let tp = localStorage.getItem("TakeProfit");
    if (!sl) {
      sl = 0;
      setActiveClassNone("active");
    }
    if (!tp) {
      tp = 25;
      setActiveClassPlus25("active");
    }
    setStopLoss(sl);
    setTakeProfit(tp);
  });

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  // TODO hack with useLocalStorageSerializeKey
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Short-Collateral-Address",
    getTokenBySymbol(chainId, defaultCollateralSymbol).address
  );
  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;

  // const getLeaderboardLink = () => {
  //   if (chainId === ARBITRUM) {
  //     return "https://www.gmx.house/arbitrum/leaderboard";
  //   }
  //   if (chainId === AVALANCHE) {
  //     return "https://www.gmx.house/avalanche/leaderboard";
  //   }
  //   return "https://www.gmx.house";
  // };

  // function getTokenLabel() {
  //   switch (true) {
  //     case isLong:
  //       return t`Long`;
  //     case isShort:
  //       return t`Short`;
  //     case isSwap:
  //       return t`Receive`;
  //     default:
  //       return "";
  //   }
  // }

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-option"],
    "2"
  );
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-slider-enabled"],
    true
  );

  const hasLeverageOption = isLeverageSliderEnabled && !isNaN(parseFloat(leverageOption));

  const [ordersToaOpen, setOrdersToaOpen] = useState(false);

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Order-option"], MARKET);
  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
    setmarketReadOnly(option == "Market" ? true : false);
    setAllowChangePersonalizedTokenPrice(true);
    setPersonalizedTokenPrice(latestPrices[ourSelectedToken.pairIndex]);
  };

  const isMarketOrder = orderOption === MARKET;
  const orderOptions = isSwap ? SWAP_ORDER_OPTIONS : LEVERAGE_ORDER_OPTIONS;
  const orderOptionLabels = { [STOP]: t`Trigger`, [MARKET]: t`Market`, [LIMIT]: t`Limit` };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  // const onTriggerPriceChange = (evt) => {
  //   setTriggerPriceValue(evt.target.value || "");
  // };

  const onTriggerRatioChange = (evt) => {
    setTriggerRatioValue(evt.target.value || "");
  };

  let positionKey;
  if (isLong) {
    positionKey = getPositionKey(account, toTokenAddress, toTokenAddress, true, nativeTokenAddress);
  }
  if (isShort) {
    positionKey = getPositionKey(account, shortCollateralAddress, toTokenAddress, false, nativeTokenAddress);
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined;
  const hasExistingPosition = existingPosition && existingPosition.size && existingPosition.size.gt(0);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokens = getTokens(chainId);
  // const fromTokens = tokens;
  const stableTokens = tokens.filter((token) => token.isStable);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  // const shortableTokens = indexTokens.filter((token) => token.isShortable);

  let toTokens = tokens;
  // if (isLong) {
  //   toTokens = indexTokens;
  // }
  // if (isShort) {
  //   toTokens = shortableTokens;
  // }

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved;
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval);

  const [isLeftMouseButtonDown, setIsLeftMouseButtonDown] = useState(false);

  const handleMouseOut = (event) => {
    if (isLeftMouseButtonDown == true) {
      event.target.blur();
    }
  };

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      setIsLeftMouseButtonDown(true);
    }
  };

  const handleMouseUp = (event) => {
    if (event.button === 0) {
      setIsLeftMouseButtonDown(false);
    }
  };

  useEffect(() => {
    if (allowChangePersonalizedTokenPrice) setPersonalizedTokenPrice(latestPrices[ourSelectedToken.pairIndex]);
  }, [latestPrices[ourSelectedToken.pairIndex]]);

  useEffect(
    () => {
      setAllowChangePersonalizedTokenPrice(true);
      setPersonalizedTokenPrice(latestPrices[ourSelectedToken.pairIndex]);
      if (!account) return;
      busdIsApproved();
    },
    [ourSelectedToken.symbol, account],
    account
  );

  function disallowChangePersonalizedTokenPrice() {
    setAllowChangePersonalizedTokenPrice(false);
    setIsLeftMouseButtonDown(true);
  }

  function manuallyPersonalizedTokenPrice(value) {
    setPersonalizedTokenPrice(Number(value));
    disallowChangePersonalizedTokenPrice();
  }

  function setCustomStopLoss(value) {
    setManualStopLoss(true);
    setStopLossValue(value);
    setStopLossText(value);
    setActiveClassStopLoss("active");
    setActiveClassNone("");
    setActiveClass10("");
    setActiveClass25("");
    setActiveClass50("");
    setActiveClass75("");
  }

  function setCustomTakeProfit(value) {
    setManualTakeProfit(true);
    setTakeProfitValue(value);
    setTakeProfitText(value);
    setActiveClassTakeProfit("active");
    setActiveClassPlus25("");
    setActiveClassPlus50("");
    setActiveClassPlus100("");
    setActiveClassPlus300("");
    setActiveactClassPlusMax("");
  }

  function formatDecimals(value) {
    value = Number(value);
    // Dos decimales
    if (value >= 1000) return value.toFixed(2);
    // Tres decimales
    else if (value >= 100 && value < 1000) return value.toFixed(3);
    // Cuatro decimales
    else if (value >= 10 && value < 100) return value.toFixed(4);
    // Cinco decimales
    else if (value >= 0.1 && value < 10) return value.toFixed(5);
    // Cinco decimales
    else if (value >= 0.01 && value < 0.1) return value.toFixed(6);
    // Seis decimales
    else if (value >= 0.001 && value < 0.01) return value.toFixed(7);
    // Siete decimales
    else if (value >= 0.0001 && value < 0.001) return value.toFixed(8);
    // Ocho decimales
    else if (value >= 0.00001 && value < 0.0001) return value.toFixed(9);
    // Nueve decimales
    else return value.toFixed(10);
  }

  // useEffect(() => {
  //   if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
  //     setIsWaitingForPluginApproval(false);
  //     helperToast.success(<div>Orders enabled!</div>);
  //   }
  // }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  // useEffect(() => {
  //   if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
  //     setIsWaitingForPositionRouterApproval(false);
  //     helperToast.success(<div>Leverage enabled!</div>);
  //   }
  // }, [
  //   needPositionRouterApproval,
  //   prevNeedPositionRouterApproval,
  //   setIsWaitingForPositionRouterApproval,
  //   isWaitingForPositionRouterApproval,
  // ]);

  // useEffect(() => {
  //   if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
  //     setIsWaitingForPluginApproval(false);
  //     helperToast.success(<div>Orders enabled!</div>);
  //   }
  // }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const USDC_ADDRESS = getContract(chainId, "USDC");
  const GNSTradingV6_2 = getContract(chainId, "Trading");
  const GFarmTradingStorage = getContract(chainId, "TradingStorage");

  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR(
    account && active && [`tokenAllowance:${active}`, chainId, USDC_ADDRESS, "allowance", account, GFarmTradingStorage],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  /*
  console.log("tokenAllowanceAddress", tokenAllowanceAddress);
  console.log("USDC_ADDRESS", USDC_ADDRESS);
  console.log("GNSTradingV6_2", GNSTradingV6_2);
  console.log("tokenAllowance", formatAmount(tokenAllowance, 18, 2, true));*/

  const { data: hasOutdatedUi } = Api.useHasOutdatedUi();

  const fromToken = getToken(chainId, fromTokenAddress);
  const toToken = getToken(chainId, toTokenAddress);
  const shortCollateralToken = getTokenInfo(infoTokens, shortCollateralAddress);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  // const renderAvailableLongLiquidity = () => {
  //   if (!isLong) {
  //     return null;
  //   }

  //   return (
  //     <div className="Exchange-info-row">
  //       <div className="Exchange-info-label">
  //         <Trans>Available Liquidity</Trans>
  //       </div>
  //       <div className="align-right">
  //         <Tooltip
  //           handle={`$${formatAmount(toTokenInfo.maxAvailableLong, USD_DECIMALS, 2, true)}`}
  //           position="right-bottom"
  //           renderContent={() => {
  //             return (
  //               <>
  //                 <StatsTooltipRow
  //                   label={t`Max ${toTokenInfo.symbol} long capacity`}
  //                   value={formatAmount(toTokenInfo.maxLongCapacity, USD_DECIMALS, 0, true)}
  //                 />
  //                 <StatsTooltipRow
  //                   label={t`Current ${toTokenInfo.symbol} long`}
  //                   value={formatAmount(toTokenInfo.guaranteedUsd, USD_DECIMALS, 0, true)}
  //                 />
  //               </>
  //             );
  //           }}
  //         ></Tooltip>
  //       </div>
  //     </div>
  //   );
  // };

  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  const toBalance = toTokenInfo ? toTokenInfo.balance : bigNumberify(0);
  const DECIMALS = 8;
  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && USDG_DECIMALS);

  const isPotentialWrap = (fromToken.isNative && toToken.isWrapped) || (fromToken.isWrapped && toToken.isNative);
  const isWrapOrUnwrap = isSwap && isPotentialWrap;
  const needApproval = tokenAllowance && toAmount && toAmount.gt(tokenAllowance);
  /*
  console.log("needApproval", needApproval);
  console.log("isWaitingForApproval", isWaitingForApproval);
  console.log("isApproving", isApproving);*/

  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);
  const prevToTokenAddress = usePrevious(toTokenAddress);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);

  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
  const collateralTokenAddress = isLong ? indexTokenAddress : shortCollateralAddress;
  // const collateralToken = getToken(chainId, collateralTokenAddress);

  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const maxToTokenOut = useMemo(() => {
    const value = toTokenInfo.availableAmount?.gt(toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount))
      ? toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount)
      : toTokenInfo.availableAmount;

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [toTokenInfo]);

  const maxToTokenOutUSD = useMemo(() => {
    return getUsd(maxToTokenOut, toTokenAddress, false, infoTokens);
  }, [maxToTokenOut, toTokenAddress, infoTokens]);

  const maxFromTokenInUSD = useMemo(() => {
    const value = fromTokenInfo.maxUsdgAmount
      ?.sub(fromTokenInfo.usdgAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDG_DECIMALS));

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [fromTokenInfo]);

  const maxFromTokenIn = useMemo(() => {
    if (!fromTokenInfo.maxPrice) {
      return bigNumberify(0);
    }
    return maxFromTokenInUSD?.mul(expandDecimals(1, fromTokenInfo.decimals)).div(fromTokenInfo.maxPrice).toString();
  }, [maxFromTokenInUSD, fromTokenInfo]);

  const [stopLossText, setStopLossText] = useState("None");
  const [activeClass10, setActiveClass10] = useState("");
  const [activeClass25, setActiveClass25] = useState("");
  const [activeClass50, setActiveClass50] = useState("");
  const [activeClass75, setActiveClass75] = useState("");
  const [activeClassNone, setActiveClassNone] = useState("");
  const [activeClassStopLoss, setActiveClassStopLoss] = useState("");

  const [takeProfitText, setTakeProfitText] = useState("25");
  const [activeClassPlus25, setActiveClassPlus25] = useState("");
  const [activeClassPlus50, setActiveClassPlus50] = useState("");
  const [activeClassPlus100, setActiveClassPlus100] = useState("");
  const [activeClassPlus300, setActiveClassPlus300] = useState("");
  const [activeClassPlusMax, setActiveactClassPlusMax] = useState("");
  const [activeClassTakeProfit, setActiveClassTakeProfit] = useState("");

  let maxSwapAmountUsd = bigNumberify(0);

  if (maxToTokenOutUSD && maxFromTokenInUSD) {
    maxSwapAmountUsd = maxToTokenOutUSD.lt(maxFromTokenInUSD) ? maxToTokenOutUSD : maxFromTokenInUSD;
  }

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (ratio.eq(0)) {
      return bigNumberify(0);
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  // useEffect(() => {
  //   if (
  //     fromToken &&
  //     fromTokenAddress === prevFromTokenAddress &&
  //     !needApproval &&
  //     prevNeedApproval &&
  //     isWaitingForApproval
  //   ) {
  //     setIsWaitingForApproval(false);
  //     helperToast.success(<div>{fromToken.symbol} approved!</div>);
  //   }
  // }, [
  //   fromTokenAddress,
  //   prevFromTokenAddress,
  //   needApproval,
  //   prevNeedApproval,
  //   setIsWaitingForApproval,
  //   fromToken.symbol,
  //   isWaitingForApproval,
  //   fromToken,
  // ]);

  // useEffect(() => {
  //   if (!toTokens.find((token) => token.address === toTokenAddress)) {
  //     if (toTokens.length > 0) {
  //       setToTokenAddress(swapOption, toTokens[0].address);
  //     }
  //   }
  // }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  // useEffect(() => {
  //   if (swapOption !== SHORT) {
  //     return;
  //   }
  //   if (toTokenAddress === prevToTokenAddress) {
  //     return;
  //   }
  //   for (let i = 0; i < stableTokens.length; i++) {
  //     const stableToken = stableTokens[i];
  //     const key = getPositionKey(account, stableToken.address, toTokenAddress, false, nativeTokenAddress);
  //     const position = positionsMap[key];
  //     if (position && position.size && position.size.gt(0)) {
  //       setShortCollateralAddress(position.collateralToken.address);
  //       return;
  //     }
  //   }
  // }, [
  //   account,
  //   toTokenAddress,
  //   prevToTokenAddress,
  //   swapOption,
  //   positionsMap,
  //   stableTokens,
  //   nativeTokenAddress,
  //   shortCollateralAddress,
  //   setShortCollateralAddress,
  // ]);

  // useEffect(() => {
  //   const updateSwapAmounts = () => {
  //     if (anchorOnFromAmount) {
  //       if (!fromAmount) {
  //         setToValue("");
  //         return;
  //       }
  //       if (toToken) {
  //         const { amount: nextToAmount } = getNextToAmount(
  //           chainId,
  //           fromAmount,
  //           fromTokenAddress,
  //           toTokenAddress,
  //           infoTokens,
  //           undefined,
  //           !isMarketOrder && triggerRatio,
  //           usdgSupply,
  //           totalTokenWeights,
  //           isSwap
  //         );

  //         const nextToValue = formatAmountFree(nextToAmount, DECIMALS, DECIMALS);
  //         setToValue(nextToValue);
  //       }
  //       return;
  //     }

  //     if (!toAmount) {
  //       setFromValue("");
  //       return;
  //     }
  //     if (fromToken) {
  //       const { amount: nextFromAmount } = getNextFromAmount(
  //         chainId,
  //         toAmount,
  //         fromTokenAddress,
  //         toTokenAddress,
  //         infoTokens,
  //         undefined,
  //         !isMarketOrder && triggerRatio,
  //         usdgSupply,
  //         totalTokenWeights,
  //         isSwap
  //       );
  //       const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);
  //       setFromValue(nextFromValue);
  //     }
  //   };

  //   const updateLeverageAmounts = () => {
  //     if (!hasLeverageOption) {
  //       return;
  //     }
  //     if (anchorOnFromAmount) {
  //       if (!fromAmount) {
  //         setToValue("");
  //         return;
  //       }

  //       const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
  //       if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
  //         const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);
  //         const toTokenPriceUsd =
  //           !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

  //         const { feeBasisPoints } = getNextToAmount(
  //           chainId,
  //           fromAmount,
  //           fromTokenAddress,
  //           collateralTokenAddress,
  //           infoTokens,
  //           undefined,
  //           undefined,
  //           usdgSupply,
  //           totalTokenWeights,
  //           isSwap
  //         );

  //         let fromUsdMinAfterFee = fromUsdMin;
  //         if (feeBasisPoints) {
  //           fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
  //         }

  //         const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);
  //         const toDenominator = bigNumberify(MARGIN_FEE_BASIS_POINTS)
  //           .mul(leverageMultiplier)
  //           .add(bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR));

  //         const nextToUsd = toNumerator.div(toDenominator);

  //         const nextToAmount = nextToUsd.mul(expandDecimals(1, DECIMALS)).div(toTokenPriceUsd);

  //         const nextToValue = formatAmountFree(nextToAmount, DECIMALS, DECIMALS);

  //         setToValue(nextToValue);
  //       }
  //       return;
  //     }

  //     if (!toAmount) {
  //       setFromValue("");
  //       return;
  //     }

  //     const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  //     if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
  //       const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);

  //       const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier);

  //       let fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

  //       const { feeBasisPoints } = getNextToAmount(
  //         chainId,
  //         fromAmount,
  //         fromTokenAddress,
  //         collateralTokenAddress,
  //         infoTokens,
  //         undefined,
  //         undefined,
  //         usdgSupply,
  //         totalTokenWeights,
  //         isSwap
  //       );

  //       if (feeBasisPoints) {
  //         const swapFees = baseFromAmountUsd.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
  //         fees = fees.add(swapFees);
  //       }

  //       const nextFromUsd = baseFromAmountUsd.add(fees);

  //       const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice);

  //       const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);

  //       setFromValue(nextFromValue);
  //     }
  //   };

  //   if (isSwap) {
  //     updateSwapAmounts();
  //   }

  //   if (isLong || isShort) {
  //     updateLeverageAmounts();
  //   }
  // }, [
  //   anchorOnFromAmount,
  //   fromAmount,
  //   toAmount,
  //   fromToken,
  //   toToken,
  //   fromTokenAddress,
  //   toTokenAddress,
  //   infoTokens,
  //   isSwap,
  //   isLong,
  //   isShort,
  //   leverageOption,
  //   fromUsdMin,
  //   toUsdMax,
  //   isMarketOrder,
  //   triggerPriceUsd,
  //   triggerRatio,
  //   hasLeverageOption,
  //   usdgSupply,
  //   totalTokenWeights,
  //   chainId,
  //   collateralTokenAddress,
  //   indexTokenAddress,
  // ]);

  let entryMarkPrice;
  let exitMarkPrice;

  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice;
  }

  useEffect(() => {
    const stopLossValue = parseInt(localStorage.getItem("StopLoss"), 10);
    const takeProfitValue = parseInt(localStorage.getItem("TakeProfit"), 10);

    switch (stopLossValue) {
      case 0:
        setActiveClassNone("active");
        break;
      case 10:
        setActiveClass10("active");
        break;
      case 25:
        setActiveClass25("active");
        break;
      case 50:
        setActiveClass50("active");
        break;
      case 75:
        setActiveClass75("active");
        break;
      default:
        break;
    }

    switch (takeProfitValue) {
      case 25:
        setActiveClassPlus25("active");
        break;
      case 50:
        setActiveClassPlus50("active");
        break;
      case 100:
        setActiveClassPlus100("active");
        break;
      case 300:
        setActiveClassPlus300("active");
        break;
      case 700:
        setActiveactClassPlusMax("active");
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    let tpTxt = "";
    let slTxt = "";
    let minTP;
    let maxTP;
    let minSL;
    let maxSL;
    if (isLong) {
      tpTxt = formatDecimals(personalizedTokenPrice + (takeProfit * personalizedTokenPrice) / 100 / leverageOption);
      if (stopLoss != 0)
        slTxt = formatDecimals(personalizedTokenPrice - (stopLoss * personalizedTokenPrice) / 100 / leverageOption);
      minTP = personalizedTokenPrice + (25 * personalizedTokenPrice) / 100 / leverageOption;
      maxTP = personalizedTokenPrice + (700 * personalizedTokenPrice) / 100 / leverageOption;
      minSL = personalizedTokenPrice - (10 * personalizedTokenPrice) / 100 / leverageOption;
      maxSL = personalizedTokenPrice - (75 * personalizedTokenPrice) / 100 / leverageOption;
    } else if (isShort) {
      tpTxt = formatDecimals(personalizedTokenPrice - (takeProfit * personalizedTokenPrice) / 100 / leverageOption);
      if (tpTxt < personalizedTokenPrice * 0.1) {
        setActiveClassPlus25("");
        setActiveClassPlus50("");
        setActiveClassPlus100("");
        setActiveClassPlus300("active");
        setActiveactClassPlusMax("");
        setActiveClassTakeProfit("box-select-input");
        setManualTakeProfit(false);
        setTakeProfit(300);
      }
      if (stopLoss != 0)
        slTxt = formatDecimals(personalizedTokenPrice + (stopLoss * personalizedTokenPrice) / 100 / leverageOption);
      minTP = personalizedTokenPrice - (25 * personalizedTokenPrice) / 100 / leverageOption;
      maxTP = personalizedTokenPrice - (700 * personalizedTokenPrice) / 100 / leverageOption;
      minSL = personalizedTokenPrice + (10 * personalizedTokenPrice) / 100 / leverageOption;
      maxSL = personalizedTokenPrice + (75 * personalizedTokenPrice) / 100 / leverageOption;
    }
    setMinTP(formatDecimals(minTP));
    setMaxTP(formatDecimals(maxTP));
    setMinSL(formatDecimals(minSL));
    setMaxSL(formatDecimals(maxSL));
    if (!manualTakeProfit) setTakeProfitText(tpTxt != 0 ? formatDecimals(tpTxt) : 0);
    if (!manualStopLoss) setStopLossText(slTxt != 0 ? formatDecimals(slTxt) : 0);
    setTakeProfitValue(parseInt(Number(takeProfitText) * 1e10));
    if (stopLossText == "None") setStopLossValue("0");
    else setStopLossValue(parseInt(Number(stopLossText) * 1e10));
    setmarketReadOnly(orderOption == "Market" ? true : false);
  }, [
    actualPrice,
    takeProfit,
    stopLoss,
    manualTakeProfit,
    manualStopLoss,
    stopLossText,
    takeProfitText,
    leverageOption,
    personalizedTokenPrice,
    swapOption,
  ]);

  let leverage = bigNumberify(0);
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees));
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit;

    if (isMarketOrder) {
      nextDelta = existingPosition.delta;
      nextHasProfit = existingPosition.hasProfit;
    } else {
      const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), existingPosition);
      nextDelta = data.delta;
      nextHasProfit = data.hasProfit;
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    });
  }

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : bigNumberify(0),
    collateral: hasExistingPosition ? existingPosition.collateral : bigNumberify(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : bigNumberify(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : bigNumberify(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined;
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice;

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : bigNumberify(0);
    const sizeDelta = toUsdMax ? toUsdMax : bigNumberify(0);
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    });
  } else if (hasLeverageOption) {
    leverage = bigNumberify(parseInt(leverageOption * BASIS_POINTS_DIVISOR));
  }

  const getSwapError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Swaps disabled, pending` + ` ${getChainName(chainId)} ` + t`upgrade`];
    }

    if (fromTokenAddress === toTokenAddress) {
      return [t`Select different tokens`];
    }

    if (!isMarketOrder) {
      if ((toToken.isStable || toToken.isUsdg) && (fromToken.isStable || fromToken.isUsdg)) {
        return [t`Select different tokens`];
      }

      if (fromToken.isNative && toToken.isWrapped) {
        return [t`Select different tokens`];
      }

      if (toToken.isNative && fromToken.isWrapped) {
        return [t`Select different tokens`];
      }
    }

    if (!fromAmount || fromAmount.eq(0) || !toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return [t`Incorrect network`];
    }
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient` + ` ${fromTokenInfo.symbol} ` + t`balance`];
    }

    const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

    if (!isMarketOrder) {
      if (!triggerRatioValue || triggerRatio.eq(0)) {
        return [t`Enter a price`];
      }

      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo);
      if (currentRate && currentRate.lt(triggerRatio)) {
        return triggerRatioInverted ? [t`Price below Mark Price`] : [t`Price above Mark Price`];
      }
    }

    if (
      !isWrapOrUnwrap &&
      toToken &&
      toTokenAddress !== USDG_ADDRESS &&
      toTokenInfo &&
      toTokenInfo.availableAmount &&
      toAmount.gt(toTokenInfo.availableAmount)
    ) {
      return [t`Insufficient liquidity`];
    }
    if (
      !isWrapOrUnwrap &&
      toAmount &&
      toTokenInfo.bufferAmount &&
      toTokenInfo.poolAmount &&
      toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(toAmount))
    ) {
      return [t`Insufficient liquidity`];
    }

    if (
      fromUsdMin &&
      fromTokenInfo.maxUsdgAmount &&
      fromTokenInfo.maxUsdgAmount.gt(0) &&
      fromTokenInfo.usdgAmount &&
      fromTokenInfo.maxPrice
    ) {
      const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS);
      const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount);

      if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
        return [`${fromTokenInfo.symbol} ` + t`pool exceeded`];
      }
    }

    return [false];
  };

  const getLeverageError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Leverage disabled, pending` + ` ${getChainName(chainId)} ` + t`upgrade`];
    }

    if (!toAmount) {
      return [t`Enter an amount`];
    } else {
      let totalAmount = toValue * (leverage / 10000);
      if (totalAmount < minLevPosDai) {
        return [t`You need at least a position of` + ` ` + minLevPosDai + ` USDT`];
      } else {
        return [t`Open Position`];
      }
    }

    if (hasOutdatedUi) {
      return [t`Page outdated, please refresh`];
    }

    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
    if (toTokenInfo && toTokenInfo.isStable) {
      return [t`${select(swapOption, { [LONG]: "Longing", [SHORT]: "Shorting" })} ${toTokenInfo.symbol} not supported`];
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`];
    }

    if (leverage && leverage.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return [t`Enter a price`];
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return [t`Min order: 10 USD`];
    }

    if (leverage && leverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: 4x`];
    }

    if (leverage && leverage.gt(150 * BASIS_POINTS_DIVISOR)) {
      return [t`Max leverage: 150x`];
    }

    if (!isMarketOrder && entryMarkPrice && triggerPriceUsd && !savedShouldDisableValidationForTesting) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return [t`Price above Mark Price`];
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return [t`Price below Mark Price`];
      }
    }

    if (isLong) {
      let requiredAmount = toAmount;
      if (fromTokenAddress !== toTokenAddress) {
        const { amount: swapAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        );
        requiredAmount = requiredAmount.add(swapAmount);

        if (toToken && toTokenAddress !== USDG_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return [t`Liquidity data not loaded`];
          }
          if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return [t`Insufficient liquidity`];
          }
        }

        if (
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return [t`Insufficient liquidity`, true, "BUFFER"];
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS);
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount);
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDG"];
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
        if (
          toTokenInfo.maxGlobalLongSize &&
          toTokenInfo.maxGlobalLongSize.gt(0) &&
          toTokenInfo.maxAvailableLong &&
          sizeUsd.gt(toTokenInfo.maxAvailableLong)
        ) {
          return [t`Max ${toTokenInfo.symbol} long exceeded`];
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = bigNumberify(0);
      if (fromTokenAddress !== shortCollateralAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          shortCollateralAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        );
        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
          return [t`Insufficient liquidity, change "Collateral In"`];
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [t`Insufficient liquidity, change "Collateral In"`, true, "BUFFER"];
        }

        if (
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS);
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount);
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDG"];
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [t`Fetching token info...`];
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice);

      if (!toTokenInfo.maxAvailableShort) {
        return [t`Liquidity data not loaded`];
      }

      if (
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [t`Max ${toTokenInfo.symbol} short exceeded`];
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [t`Insufficient liquidity, change "Collateral In"`];
      }
    }

    return [false];
  };

  let minCollateral;
  let maxCollateral;

  const getToLabel = () => {
    if (isSwap) {
      return t`Receive`;
    }

    let min = minLevPosDai / maxLeverage;

    /*let max = (maxCollateralP * 10 ** 7) / tvl;
    maxCollateral = parseInt((max * 1000).toFixed(0));*/

    return t`Collateral` + ` (${Math.ceil(min)}-${formatNumber(Math.ceil(groupMaxCollateral))})`;
  };

  const getError = () => {
    if (isSwap) {
      return getSwapError();
    }
    return getLeverageError();
  };

  const renderOrdersToa = () => {
    if (!ordersToaOpen) {
      return null;
    }

    return (
      <OrdersToa
        setIsVisible={setOrdersToaOpen}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    );
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }

    if (needApproval) return true;

    if (ourSelectedToken.marketStatus == "CLOSED") return false;

    if (isStopOrder) {
      return true;
    }
    if (!active) {
      return true;
    }

    // const [error, modal] = getError();
    // if (error && !modal) {
    //   return false;
    // }

    /*
    // FOR TESTING PURPOSES WITH OPENING FEES

    let openingFees = toValue * (leverage / 1e4) * (openFee / 100);
    console.log("openingFees", openingFees);

    if (
      (isLong && maxOpenInterest - longOpenInterest <= toValue * (leverage / 10000) - openingFees) ||
      (!isLong && maxOpenInterest - shortOpenInterest <= toValue * (leverage / 10000) - openingFees)
    ) {
      return false;
    }
    */

    if (Number(toValue) > Number(groupMaxCollateral)) {
      return false;
    }

    if (
      (isLong && groupMaxCollateral - groupCollateralLong < toValue * (leverage / 10000)) ||
      (!isLong && groupMaxCollateral - groupCollateralShort < toValue * (leverage / 10000))
    ) {
      return false;
    }

    if (
      (isLong && maxOpenInterest - longOpenInterest < toValue * (leverage / 10000)) ||
      (!isLong && maxOpenInterest - shortOpenInterest < toValue * (leverage / 10000))
    ) {
      return false;
    }

    // if (
    //   (isLong && groupMaxCollateral <= groupCollateralLong + parseFloat(toValue)) ||
    //   (!isLong && groupMaxCollateral <= groupCollateralShort + parseFloat(toValue))
    // ) {
    //   return false;
    // }

    // SL & TP Limits
    // Margin 0.001% to avoid problem with decimals
    if (isLong) {
      if (
        !(
          formatDecimals(takeProfitValue / 1e10) >= Number(minTP) - Number(minTP) / 1000 &&
          formatDecimals(takeProfitValue / 1e10) <= Number(maxTP) + Number(maxTP) / 1000 &&
          formatDecimals(stopLossValue / 1e10) <= Number(minSL) + Number(minSL) / 1000 &&
          (formatDecimals(stopLossValue / 1e10) >= Number(maxSL) - Number(maxSL) / 1000 ||
            formatDecimals(stopLossValue / 1e10) == 0)
        )
      )
        return false;
    }
    if (isShort) {
      if (
        !(
          formatDecimals(takeProfitValue / 1e10) <= Number(minTP) + Number(minTP) / 1000 &&
          formatDecimals(takeProfitValue / 1e10) >= Number(maxTP) - Number(maxTP) / 1000 &&
          (formatDecimals(stopLossValue / 1e10) >= Number(minSL) - Number(minSL) / 1000 ||
            formatDecimals(stopLossValue / 1e10) == 0) &&
          formatDecimals(stopLossValue / 1e10) <= Number(maxSL) + Number(maxSL) / 1000
        )
      )
        return false;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      IS_PROD || console.log(">>>>> needOrderBookApproval && isWaitingForPluginApproval");
      return false;
    }
    /*if ((needApproval && isWaitingForApproval) || isApproving) {
      console.log(">>>>> needApproval && isWaitingForApproval");
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      console.log(">>>> needPositionRouterApproval && isWaitingForPositionRouterApproval");
      return false;
    }*/
    if (isPositionRouterApproving) {
      IS_PROD || console.log(">>>> isPositionRouterApproving");
      return false;
    }
    if (isApproving) {
      IS_PROD || console.log(">>>> isApproving");
      return false;
    }
    if (needApproval) {
      return true;
    }
    if (isSubmitting) {
      return false;
    }
    if (!toAmount) {
      return false;
    } else {
      let totalAmount = toValue * (leverage / 10000);
      if (totalAmount < minLevPosDai) {
        return false;
      }
    }

    return true;
  };

  const getPrimaryText = () => {
    const ourPar = market24[ourSelectedToken.pairIndex];
    if (!active) {
      return t`Connect Wallet`;
    }
    if (needApproval) return t`Approve USDT`;

    if (ourPar == "CLOSED") return t`Market Closed`;

    if (isStopOrder) {
      return t`Open a position`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (Number(toValue) > Number(groupMaxCollateral)) {
      return t`Max Collateral Exceeded`;
    }

    if (
      (isLong && groupMaxCollateral - groupCollateralLong < toValue * (leverage / 10000)) ||
      (!isLong && groupMaxCollateral - groupCollateralShort < toValue * (leverage / 10000))
    ) {
      return t`Position size is higher than max group open interest`;
    }

    if (
      (isLong && maxOpenInterest - longOpenInterest < toValue * (leverage / 10000)) ||
      (!isLong && maxOpenInterest - shortOpenInterest < toValue * (leverage / 10000))
    ) {
      return t`Position size is higher than max open interest`;
    }

    // SL & TP Limits
    if (isLong) {
      // Margin 0.001% to avoid problem with decimals
      if (Number(takeProfitValue / 1e10) < Number(minTP) - Number(minTP) / 1000)
        return t`Take profit value is too low.`;
      else if (Number(takeProfitValue / 1e10) > Number(maxTP) + Number(maxTP) / 1000)
        return t`Take profit value is too high.`;
      else if (Number(stopLossValue / 1e10) > Number(minSL) + Number(minSL) / 1000)
        return t`Stop loss value is too high.`;
      else if (
        Number(stopLossValue / 1e10) < Number(maxSL) - Number(maxSL) / 1000 &&
        formatDecimals(stopLossValue / 1e10) != 0
      )
        return t`Stop loss value is too low.`;
    }

    if (isShort) {
      if (Number(takeProfitValue / 1e10) > Number(minTP) + Number(minTP) / 1000)
        return t`Take profit value is too high.`;
      else if (Number(takeProfitValue / 1e10) < Number(maxTP) - Number(maxTP) / 1000)
        return t`Take profit value is too low.`;
      else if (
        Number(stopLossValue / 1e10) < Number(minSL) - Number(minSL) / 1000 &&
        formatDecimals(stopLossValue / 1e10) != 0
      )
        return t`Stop loss value is too low.`;
      else if (Number(stopLossValue / 1e10) > Number(maxSL) + Number(maxSL) / 1000)
        return t`Stop loss value is too high.`;
    }

    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage...`;
    }
    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`;
    }
    if (needPositionRouterApproval) {
      return t`Enable Leverage`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ` + `${fromToken.symbol}...`;
    }
    if (needApproval) {
      return t`Approve ` + `${fromToken.symbol}`;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling Orders...`;
    }
    if (isPluginApproving) {
      return t`Enabling Orders...`;
    }
    if (needOrderBookApproval) {
      return t`Enable Orders`;
    }

    if (!isMarketOrder)
      return t`Create ` + `${orderOption.charAt(0) + orderOption.substring(1).toLowerCase()}` + t` Order`;

    if (isSwap) {
      if (toUsdMax && toUsdMax.lt(fromUsdMin.mul(95).div(100))) {
        return t`High Slippage, Swap Anyway`;
      }
      return t`Swap`;
    }

    if (isLong) {
      const indexTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
      if (indexTokenInfo && indexTokenInfo.minPrice) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          indexTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        );
        const nextToAmountUsd = nextToAmount
          .mul(indexTokenInfo.minPrice)
          .div(expandDecimals(1, indexTokenInfo.decimals));
        if (fromTokenAddress === USDG_ADDRESS && nextToAmountUsd.lt(fromUsdMin.mul(98).div(100))) {
          return t`High USDG Slippage, Long Anyway`;
        }
      }
      return t`Long ` + `${toToken.symbol}`;
    }

    return t`Short ` + `${toToken.symbol}`;
  };

  // const onSelectFromToken = (token) => {
  //   setFromTokenAddress(swapOption, token.address);
  //   setIsWaitingForApproval(false);

  //   if (isShort && token.isStable) {
  //     setShortCollateralAddress(token.address);
  //   }
  // };

  // const onSelectShortCollateralAddress = (token) => {
  //   setShortCollateralAddress(token.address);
  // };

  // const onSelectToToken = (token) => {
  //   setToTokenAddress(swapOption, token.address);
  // };

  // const onFromValueChange = (e) => {
  //   setAnchorOnFromAmount(true);
  //   setFromValue(e.target.value);
  // };

  const onToValueChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
    setAnchorOnFromAmount(false);
    setToValue(e.target.value);
  };

  // Spread calculation
  const posDai = Number(parseInt(leverage / 1e4) * parseFloat(toValue));
  const baseSpreadP = Number(spread);
  const onePercentDepth = Number(isLong ? onePercentDepthAbove : onePercentDepthBelow);
  const interestDai = Number(isLong ? longOpenInterest : shortOpenInterest);
  const priceImpactP = (interestDai + posDai / 2) / onePercentDepth;
  const spreadP = onePercentDepth > 0 ? baseSpreadP + priceImpactP : baseSpreadP;
  const priceIncludingSpread = formatDecimals(
    !isLong
      ? latestPrices[ourSelectedToken.pairIndex] * (1 - spreadP / 100)
      : latestPrices[ourSelectedToken.pairIndex] * (1 + spreadP / 100)
  );

  /* alfaori: REV
  const switchTokens = () => {
    if (fromAmount && toAmount) {
      if (anchorOnFromAmount) {
        setToValue(formatAmountFree(fromAmount, fromToken.decimals, 8));
      } else {
        setFromValue(formatAmountFree(toAmount, USDG_DECIMALS, 8));
      }
      setAnchorOnFromAmount(!anchorOnFromAmount);
    }
    setIsWaitingForApproval(false);

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    updatedTokenSelection[swapOption] = {
      from: toTokenAddress,
      to: fromTokenAddress,
    };
    setTokenSelection(updatedTokenSelection);
  };
*/
  const wrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, library.getSigner());
    callContract(chainId, contract, "deposit", {
      value: fromAmount,
      sentMsg: t`Swap submitted.`,
      successMsg:
        t`Swapped ` +
        `${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol} for ${formatAmount(
          toAmount,
          USDG_DECIMALS,
          4,
          true
        )} ${toToken.symbol}!`,
      failMsg: t`Swap failed.`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const unwrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, library.getSigner());
    callContract(chainId, contract, "withdraw", [fromAmount], {
      sentMsg: t`Swap submitted!`,
      failMsg: t`Swap failed.`,
      successMsg:
        t`Swapped ` +
        `${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol} for ${formatAmount(
          toAmount,
          USDG_DECIMALS,
          4,
          true
        )} ${toToken.symbol}!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  /*
  const swap = async () => {
    if (fromToken.isNative && toToken.isWrapped) {
      wrap();
      return;
    }

    if (fromTokenAddress.isWrapped && toToken.isNative) {
      unwrap();
      return;
    }

    setIsSubmitting(true);
    let path = [fromTokenAddress, toTokenAddress];
    if (anchorOnFromAmount) {
      const { path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      );
      if (multiPath) {
        path = multiPath;
      }
    } else {
      const { path: multiPath } = getNextFromAmount(
        chainId,
        toAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      );
      if (multiPath) {
        path = multiPath;
      }
    }

    let method;
    let contract;
    let value;
    let params;
    let minOut;
    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(true);
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    if (!isMarketOrder) {
      minOut = toAmount;
      Api.createSwapOrder(chainId, library, path, fromAmount, minOut, triggerRatio, nativeTokenAddress, {
        sentMsg: t`Swap Order submitted!`,
        successMsg: t`Swap Order created!`,
        failMsg: t`Swap Order creation failed.`,
        pendingTxns,
        setPendingTxns,
      })
        .then(() => {
          setIsConfirming(false);
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsPendingConfirmation(false);
        });
      return;
    }

    path = replaceNativeTokenAddress(path, nativeTokenAddress);
    method = "swap";
    value = bigNumberify(0);
    if (toTokenAddress === AddressZero) {
      method = "swapTokensToETH";
    }

    minOut = toAmount.mul(BASIS_POINTS_DIVISOR - allowedSlippage).div(BASIS_POINTS_DIVISOR);
    params = [path, fromAmount, minOut, account];
    if (fromTokenAddress === AddressZero) {
      method = "swapETHToTokens";
      value = fromAmount;
      params = [path, minOut, account];
    }
    contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner());

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: t`Swap ` + `${!isMarketOrder ? " order " : ""} submitted!`,
      successMsg:
        t`Swapped ` +
        `${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol} for ${formatAmount(
          toAmount,
          USDG_DECIMALS,
          4,
          true
        )} ${toToken.symbol}!`,
      failMsg: t`Swap failed.`,
      setPendingTxns,
    })
      .then(async () => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };*/

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress];

    if (path[0] === USDG_ADDRESS) {
      if (isLong) {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        path.push(stableToken.address);
      } else {
        path.push(shortCollateralAddress);
      }
    }

    const minOut = 0;
    const indexToken = getToken(chainId, indexTokenAddress);
    const successMsg =
      t`
      Created limit order for ` +
      `${indexToken.symbol} ${isLong ? "Long" : "Short"}: ${formatAmount(toUsdMax, USD_DECIMALS, 2)} USD!
    `;
    return Api.createIncreaseOrder(
      chainId,
      library,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      minOut,
      toUsdMax,
      collateralTokenAddress,
      isLong,
      triggerPriceUsd,
      {
        pendingTxns,
        setPendingTxns,
        sentMsg: t`Limit order submitted!`,
        successMsg,
        failMsg: t`Limit order creation failed.`,
      }
    )
      .then(() => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  // if (!attachedOnChain && userReferralCode) {
  //   referralCode = userReferralCode;
  // }

  const increasePosition = async () => {
    setIsSubmitting(true);
    const tokenAddress0 = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
    const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
    let path = [indexTokenAddress]; // assume long
    if (toTokenAddress !== fromTokenAddress) {
      path = [tokenAddress0, indexTokenAddress];
    }

    if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
      path = [nativeTokenAddress];
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
      path = [nativeTokenAddress];
    }

    if (isShort) {
      path = [shortCollateralAddress];
      if (tokenAddress0 !== shortCollateralAddress) {
        path = [tokenAddress0, shortCollateralAddress];
      }
    }

    const refPrice = isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0);

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDG_ADDRESS && isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      );
      if (nextToAmount.eq(0)) {
        helperToast.error(t`Insufficient liquidity`);
        return;
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath);
      }
    }

    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      0, // _minOut
      toUsdMax, // _sizeDelta
      isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
      AddressZero, // _callbackTarget
    ];

    let method = "createIncreasePosition";
    let value = minExecutionFee;
    if (fromTokenAddress === AddressZero) {
      method = "createIncreasePositionETH";
      value = boundedFromAmount.add(minExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        toUsdMax, // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
        AddressZero, // _callbackTarget
      ];
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      helperToast.error(
        t`Leave at least ` + `${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    const contractAddress = getContract(chainId, "PositionRouter");
    const contract = new ethers.Contract(contractAddress, PositionRouter.abi, library.getSigner());
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexToken.symbol;
    const longOrShortText = isLong ? t`Long` : t`Short`;
    const successMsg =
      t`Requested increase of ` +
      ` ${tokenSymbol} ${longOrShortText} by ${formatAmount(toUsdMax, USD_DECIMALS, 2)} USD.`;

    callContract(chainId, contract, method, params, {
      value,
      setPendingTxns,
      sentMsg: `${longOrShortText} submitted.`,
      failMsg: `${longOrShortText} failed.`,
      successMsg,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        setIsConfirming(false);

        const key = getPositionKey(account, path[path.length - 1], indexTokenAddress, isLong);
        let nextSize = toUsdMax;
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax);
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === STOP) {
      setOrderOption(MARKET);
    }

    // setAnchorOnFromAmount(true);
    // setFromValue("");
    // setToValue("");
    // setTriggerPriceValue("");
    // setTriggerRatioValue("");

    if (opt === SHORT && infoTokens) {
      const fromToken = getToken(chainId, tokenSelection[opt].from);
      if (fromToken && fromToken.isStable) {
        setShortCollateralAddress(fromToken.address);
      } else {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        setShortCollateralAddress(stableToken.address);
      }
    }
  };

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    if (needOrderBookApproval) {
      approveOrderBook();
      return;
    }

    setIsPendingConfirmation(true);

    if (isSwap) {
      //swap();
      return;
    }

    if (orderOption === LIMIT) {
      createIncreaseOrder();
      return;
    }
    increasePosition();
  };

  // function approveFromToken() {
  //   approveTokens({
  //     setIsApproving,
  //     library,
  //     tokenAddress: fromToken.address,
  //     spender: routerAddress,
  //     chainId: chainId,
  //     onApproveSubmitted: () => {
  //       setIsWaitingForApproval(true);
  //     },
  //     infoTokens,
  //     getTokenInfo,
  //     pendingTxns,
  //     setPendingTxns,
  //   });
  // }

  const getTestBusd = async () => {
    await busdContract.getFreeTokens();
  };

  const [claimableText, setClaimableText] = useState();

  const getClaimableButton = async () => {
    try {
      const claimableAmount = await busdContract.claimableAmount();
      setClaimableText(claimableAmount._hex / 1e21);
    } catch (e) {
      console.error("Error getting claimable amount", e);
    }
  };

  const [disabled, setDisabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  const getLastGetTokens = async () => {
    try {
      const lastGetTokens = await busdContract.lastGetTokens(account);
      const timeStamp = parseInt(lastGetTokens._hex, 16);

      const currentTime = new Date().getTime() / 1000;
      const differenceInSeconds = currentTime - timeStamp;

      setTimeRemaining(Math.max(0, 24 * 60 * 60 - differenceInSeconds));
      setDisabled(differenceInSeconds < 24 * 60 * 60);
    } catch (e) {
      console.error("Error getting last get tokens", e);
    }
  };

  const getPairGroup = (pairIndex) => {
    return pares.find((el) => el[1] == pairIndex)[2];
  };

  useEffect(() => {
    if (!active) return;
    try {
      getLastGetTokens();
      getClaimableButton();
    } catch (e) {
      console.error("Error getting last get tokens", e);
    }
  }, [chainId]);

  useEffect(() => {
    if (!active) return;
    getLastGetTokens();

    const intervalId = setInterval(() => {
      getLastGetTokens();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    // Pad the hours and minutes with '0' if they are less than 10
    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}`;
  };

  const getPositionSize = (toValue, leverage) => {
    let lvrg = leverage / 1e4;
    const positionSize = toValue * (1 - (lvrg * openingFees) / 100) * lvrg;
    setPositionSize(positionSize);
    return positionSize;
  };

  useEffect(() => {
    if (toValue && leverage) {
      getPositionSize(toValue, leverage);
    }
  }, [toValue, leverage, openingFees]);

  useEffect(() => {
    if (!active) return;
    contractInfo[PairsStorage].contract
      .fees(pairGroup)
      .then((data) => {
        let { openFeeP, closeFeeP, nftLimitOrderFeeP } = data;
        let _openFeeP = parseInt(openFeeP) / 1e10;
        let _nftLimitOrderFeeP = parseInt(nftLimitOrderFeeP) / 1e10;
        let calculatedFee = 2 * _openFeeP + _nftLimitOrderFeeP;
        setOpeningFees(calculatedFee);
      })
      .catch((err) => {
        console.error("Error getting fees", err);
      });
  }, [pairGroup]);

  useEffect(() => {
    let openingFees = 0.14;
    let pairGroup = getPairGroup(ourSelectedToken.pairIndex);
    setPairGroup(pairGroup);
  }, [ourSelectedToken.pairIndex]);

  async function busdIsApproved() {
    if (!active) return;
    try {
      const res = await busdContract.allowance(account, GNSTradingV6_2);
      if (res == 0) {
        setisApproved(false);
      } else {
        setisApproved(true);
      }
    } catch (e) {
      console.error("Error getting BUSD allowance", e);
    }
  }

  async function approveBUSD() {
    setIsApproving(true);
    const res = await busdContract.approve(GFarmTradingStorage, ethers.constants.MaxUint256);
    await res.wait();
    setIsApproving(false);
    busdIsApproved();
  }

  const { getReferrerAddress } = useLoyaltyReferrals({
    account: account,
  });

  const onClickPrimary = async () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    if (needApproval) {
      approveBUSD();
      return;
    }
    /*
    console.log("!!!");
    console.log(tokenAllowance);
    console.log(tokenAllowanceAddress);
    console.log(fromAmount);
    console.log(fromToken);
    console.log(toToken);
    console.log("fromToken " + toToken.address);
    console.log("fromToken " + fromToken.address);
    console.log("fromTokenAddress " + fromTokenAddress);
    console.log(toTokens);
    console.log(infoTokens);
    */

    if (isStopOrder) {
      setOrderOption(MARKET);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent.`,
        failMsg: t`Enable leverage failed.`,
      });
      return;
    }

    if (toValue < 10 || Number(toValue) > Number(groupMaxCollateral)) {
      helperToast.error(
        `The amount must be higher than 10 and lower than ${groupMaxCollateral}. You can't use decimal numbers.`
      );
      return;
    }

    if (Number(toValue) > Number(userBusdBalance)) {
      helperToast.error("The amount can't be higher than your balance.");
      return;
    }

    if (Number(slippageAmount) < 0.01 || Number(slippageAmount) > 100) {
      helperToast.error("The slippage must be higher than 0.01 and lower than 100. You can't use decimal numbers.");
      return;
    }

    let totalAmount = toValue * (leverage / 10000);
    if (totalAmount < Number(minLevPosDai)) {
      helperToast.error(
        "The total amount must be higher than " + minLevPosDai + " BUSD. Actually you are using " + totalAmount
      );
      return;
    }
    let _leverage = 0;
    const price = parseInt(personalizedTokenPrice * 1e10);
    const slippage = slippageAmount * 1e10;

    let orderType = 0;

    // console.log(">>>>>>>>>>>>>>>>>>> SL: ", typeof(sl));
    // console.log(">>>>>>>>>>>>>>>>>>> SL: ", sl);
    // console.log(">>>>>>>>>>>>>>>>>>> TP: ", tp);

    if (hasLeverageOption) {
      _leverage = leverageOption;
    }

    if (orderOption == LIMIT) {
      orderType = 1;
    }

    const tuple = [
      account.toString(),
      ourSelectedToken.pairIndex,
      0,
      0,
      toAmount.toString(),
      price,
      isLong,
      _leverage,
      takeProfitValue,
      stopLossValue,
    ];

    // Get Referral Address from LocalStorage
    const referrerAddress = await getReferrerAddress(account);

    IS_PROD || console.log(">>> tuple", tuple);
    IS_PROD || console.log(">>> orderType", orderType);
    IS_PROD || console.log(">>> pairIndex", ourSelectedToken.pairIndex);
    IS_PROD || console.log(">>> slippage", slippage);
    IS_PROD || console.log(">>> personalizedTokenPrice", personalizedTokenPrice);
    IS_PROD || console.log(">>> Referrer", referrerAddress);

    const position = {
      trader: account.toString(),
      buy: isLong,
      chainId: chainId,
      daiSentToTrader: 0,
      event: "Openning",
      pairIndex: ourSelectedToken.pairIndex,
      index: 0,
      tp: takeProfitValue,
      sl: stopLossValue,
      leverage: _leverage,
      initialPosToken: 0,
      orderId: 0,
      initialPosToken: 0,
      open: true,
      openPrice: 0,
      percentProfit: 0,
      positionSizeDai: 0,
      price: 0,
      priceImpactP: 0,
    };

    //IS_PROD || console.log(">>> position", position);
    tradingV6Contract
      .startTrade(tuple, orderType, slippage, referrerAddress)
      .then((val) => {
        helperToast.success("The order has been created successfully");
        //getOurPositions();
        //getOurOrders();
      })
      .catch((err) => {
        console.error("There was an error:", err);
        if (err.code == 4001) {
          helperToast.error("User has denied the transaction");
        } else if (err.code == -32603) {
          helperToast.error("The transaction has been reverted");
        } else if (err.reason.includes("MAX_TRADES_PER_PAIR")) {
          helperToast.error("You have reached the maximum number of open position for this pair");
        } else if (err.reason.includes("MAX_PENDING_ORDERS")) {
          helperToast.error("You have reached the maximum pending orders. Cancell any to continue");
        } else if (err.reason.includes("WRONG_TP")) {
          helperToast.error("The take profit value must be higher than the current price");
        } else if (err.reason.includes("WRONG_SL")) {
          helperToast.error("The stop loss value must be lower than the current price");
        } else if (err.reason.includes("BELOW_MIN_POS")) {
          helperToast.error("The position size must be higher than the minimum position size");
        } else if (err.reason.includes("LEVERAGE_INCORRECT")) {
          helperToast.error("The leverage must be higher than 4x and lower than 150x");
        } else if (err.reason.includes("NO_CORRESPONDING_NFT_SPREAD_REDUCTION")) {
          helperToast.error("You don't have a NFT with spread reduction");
        } else if (err.reason.includes("PAUSED")) {
          helperToast.error("Trading is paused");
        } else if (err.reason.includes("INSUFFICIENT_BALANCE")) {
          helperToast.error("You don't have enough balance");
        } else if (err.reason.includes("INSUFFICIENT_ALLOWANCE")) {
          helperToast.error("You don't have enough allowance");
        } else if (err.reason.includes("PRICE_IMPACT_TOO_HIGH")) {
          helperToast.error("Price Impact too high");
        } else {
          helperToast.error("An error ocurred, open the console for more information");
        }
      });
    return;

    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    const [modal, errorCode] = getError();

    if (modal) {
      setModalError(errorCode);
      return;
    }

    if (isSwap) {
      if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
        wrap();
        return;
      }

      if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
        unwrap();
        return;
      }
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const isStopOrder = orderOption === STOP;
  const showFromAndToSection = !isStopOrder;
  // const showTriggerPriceSection = !isSwap && !isMarketOrder && !isStopOrder;
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder;

  let fees;
  let feesUsd;
  let feeBps;
  let swapFees;
  let positionFee;
  if (isSwap) {
    if (fromAmount) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      );
      if (feeBasisPoints !== undefined) {
        fees = fromAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
        const feeTokenPrice =
          fromTokenInfo.address === USDG_ADDRESS ? expandDecimals(1, USD_DECIMALS) : fromTokenInfo.maxPrice;
        feesUsd = fees.mul(feeTokenPrice).div(expandDecimals(1, fromTokenInfo.decimals));
      }
      feeBps = feeBasisPoints;
    }
  } else if (toUsdMax) {
    positionFee = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    feesUsd = positionFee;

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdgSupply,
      totalTokenWeights,
      isSwap
    );
    if (feeBasisPoints) {
      swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
      feesUsd = feesUsd.add(swapFees);
    }
    feeBps = feeBasisPoints;
  }

  function getSpreadColor(spreadP) {
    const startColor = { r: 128, g: 128, b: 128 };
    const endColor = { r: 226, g: 62, b: 62 };

    if (spreadP <= 0.1) {
      return `rgba(${startColor.r}, ${startColor.g}, ${startColor.b}, 1)`;
    }

    if (spreadP > 0.5) {
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 1)`;
    }

    const t = 0.5 + 0.5 * ((spreadP - 0.1) / (0.5 - 0.1));

    // Interpola los valores RGB
    const r = startColor.r + t * (endColor.r - startColor.r);
    const g = startColor.g + t * (endColor.g - startColor.g);
    const b = startColor.b + t * (endColor.b - startColor.b);

    // Retorna el color interpolado
    return `rgba(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}, 1)`;
  }

  const baseUrl = getAppBaseUrlGen();
  const [buttonText, setButtonText] = useState("");
  const [buttonText2, setButtonText2] = useState("");

  const [display, setDisplay] = useState("");
  const [display2, setDisplay2] = useState("");

  useEffect(() => {
    if (window.location.href === `${baseUrl}/`) {
      setDisplay("none");
    } else {
      setDisplay("flex");
    }

    if (window.location.href === `${baseUrl}/trading`) {
      setDisplay2("flex");
    } else {
      setDisplay2("none");
    }

    if (!IS_PROD) {
      setDisplay("flex");
      setDisplay2("flex");
    }

    //console.log(window.location.href);
  }, [window.location.href]);

  useEffect(() => {
    if (chainId === ZKSYNC_TESTNET) {
      setButtonText("Get Test ETH");
      setButtonText2("Bridge zkSync");
    } else if (chainId === SHARDEUM_TESTNET) {
      setButtonText("Get Test SHM");
      setButtonText2(false);
    } else if (chainId === OPBNB_TESTNET) {
      setButtonText("Get Test BNB");
      setButtonText2("Bridge opBNB");
    } else if (chainId === ZETACHAIN_TESTNET) {
      setButtonText("Get Test Zeta");
      setButtonText2(false);
    } else if (chainId === TEN_TESTNET) {
      setButtonText("Get Test ETH");
      setButtonText2(false);
    } else if (chainId === IOTA_TESTNET) {
      setButtonText("Get IOTA");
      setButtonText2(false);
    } else if (chainId === BERACHAIN_TESTNET) {
      setButtonText("Get BERA (Faucet 1)");
      setButtonText2("Get BERA (Faucet 2)");
    } else if (chainId === SHIMMER_MAINNET) {
      setButtonText(false);
      setButtonText2(false);
    } else if (chainId == FIRE_CHAIN_TESTNET) {
      setButtonText("Get Test 5IRE");
      setButtonText2(false);
    } else if (chainId == FIRE_CHAIN_MAINNET) {
      setButtonText(false);
      setButtonText2(false);
    }
  }, [chainId]);

  const [leverageMarks, setLeverageMarks] = useState({
    4: "4x",
    25: "25x",
    50: "50x",
    75: "75x",
    100: "100x",
    125: "125x",
    150: "150x",
    175: "175x",
    200: "200x",
  });

  React.useEffect(() => {
    if (account) {
      if (minLeverage == 10 && maxLeverage == 1000) {
        setLeverageMarks({
          10: "10x",
          100: "100x",
          200: "200x",
          300: "300x",
          400: "400x",
          500: "500x",
          600: "600x",
          700: "700x",
          800: "800x",
          900: "900x",
          1000: "1000x",
        });
      } else if (minLeverage == 4 && maxLeverage == 150) {
        setLeverageMarks({
          4: "4x",
          25: "25x",
          50: "50x",
          75: "75x",
          100: "100x",
          125: "125x",
          150: "150x",
        });
      } else if (minLeverage == 5 && maxLeverage == 100) {
        setLeverageMarks({
          5: "5x",
          25: "25x",
          50: "50x",
          75: "75x",
          100: "100x",
        });
      } else if (minLeverage == 4 && maxLeverage == 50) {
        setLeverageMarks({
          4: "4x",
          10: "10x",
          25: "25x",
          40: "40x",
          50: "50x",
        });
      } else if (minLeverage == 3 && maxLeverage == 30) {
        setLeverageMarks({
          3: "3x",
          10: "10x",
          20: "20x",
          30: "30x",
        });
      } else if (minLeverage == 2 && maxLeverage == 35) {
        setLeverageMarks({
          2: "2x",
          10: "10x",
          20: "20x",
          35: "35x",
        });
      } else if (minLeverage == 2 && maxLeverage == 250) {
        setLeverageMarks({
          2: "2x",
          50: "50x",
          100: "100x",
          150: "150x",
          200: "200x",
          250: "250x",
        });
      } else if (minLeverage == 2 && maxLeverage == 150) {
        setLeverageMarks({
          2: "2x",
          25: "25x",
          50: "50x",
          75: "75x",
          100: "100x",
          125: "125x",
          150: "150x",
        });
      } else if (minLeverage == 10 && maxLeverage == 500) {
        setLeverageMarks({
          10: "10x",
          100: "100x",
          200: "200x",
          300: "300x",
          400: "400x",
          500: "500x",
        });
      }
    }
  }, [minLeverage, maxLeverage]);

  if (!fromToken || !toToken) {
    return null;
  }

  let hasZeroBorrowFee = false;
  let borrowFeeText;
  if (isLong && toTokenInfo && toTokenInfo.fundingRate) {
    borrowFeeText = formatAmount(toTokenInfo.fundingRate, 4, 4) + "% / 1h";
    if (toTokenInfo.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }
  if (isShort && shortCollateralToken && shortCollateralToken.fundingRate) {
    borrowFeeText = formatAmount(shortCollateralToken.fundingRate, 4, 4) + "% / 1h";
    if (shortCollateralToken.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }

  const getStopLossText = () => {
    let stopLossText;
    if (stopLoss == 0) {
      stopLossText = "None";
    } else if (stopLoss == 25) {
      stopLossText = "25%";
    } else if (stopLoss == 50) {
      stopLossText = "50%";
    } else if (stopLossText == 75) {
      stopLossText = "75%";
    } else {
      stopLossText = "None";
    }

    return stopLossText;
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const SWAP_LABELS = {
    [LONG]: t`Long`,
    [SHORT]: t`Short`,
    [SWAP]: t`Swap`,
  };

  return (
    <div
      className="Exchange-swap-box"
      style={{
        overflow: overFlow,
        marginLeft: "-6px",
      }}
      onClick={(e) => {
        handleInnerClick(e);
      }}
    >
      {/* TODO: Implementar botones get ** y bridge ** aquí */}
      {active && (
        <>
          {buttonText && ( // Verifica si buttonText tiene un valor truthy (no vacío, no undefined, no null)
            <button
              style={{
                display: display2,
                backgroundColor: "#222222",
                border: "1px solid #3C3D43",
                fontFamily: "Lato",
                color: "white",
                textAlign: "center",
                justifyContent: "center",
                padding: "5px",
                borderRadius: "5px",
                width: "98%",
                margin: "2px 5px",
                transition: "300ms",
              }}
              onClick={() => {
                switch (chainId) {
                  case ZKSYNC_TESTNET:
                    window.open("https://www.alchemy.com/faucets/ethereum-sepolia", "_blank");
                    break;
                  case SHARDEUM_TESTNET:
                    window.open("https://faucet-dapps.shardeum.org", "_blank");
                    break;
                  case OPBNB_TESTNET:
                    window.open("https://testnet.bnbchain.org/faucet-smart", "_blank");
                    break;
                  case ZETACHAIN_TESTNET:
                    window.open("https://labs.zetachain.com/get-zeta", "_blank");
                    break;
                  case TEN_TESTNET:
                    window.open("https://testnet.ten.xyz", "_blank");
                    break;
                  case IOTA_TESTNET:
                    window.open("https://evm-toolkit.evm.testnet.iotaledger.net", "_blank");
                    break;
                  case BERACHAIN_TESTNET:
                    window.open("https://bartio.faucet.berachain.com", "_blank");
                    break;
                  case FIRE_CHAIN_TESTNET:
                    window.open("https://explorer.ga.5ire.network/faucet");
                }
              }}
              className="btn-onhover"
            >
              {buttonText}
            </button>
          )}
          {buttonText2 && ( // Condición para buttonText2 y filtrado de chainId
            <button
              style={{
                display: display2,
                backgroundColor: "#222222",
                border: "1px solid #3C3D43",
                fontFamily: "Lato",
                color: "white",
                textAlign: "center",
                justifyContent: "center",
                padding: "5px",
                margin: "2px 5px",
                borderRadius: "5px",
                width: "98%",
                transition: "500ms",
              }}
              className="btn-onhover"
              onClick={() => {
                if (chainId === ZKSYNC_TESTNET) {
                  window.open("https://goerli.bridge.zksync.io/", "_blank");
                } else if (chainId === OPBNB_TESTNET) {
                  window.open("https://opbnb-testnet-bridge.bnbchain.org/", "_blank");
                } else if (chainId === BERACHAIN_TESTNET) {
                  window.open("https://faucet.quicknode.com/berachain/bartio", "_blank");
                }
              }}
            >
              {buttonText2}
            </button>
          )}

          <button
            style={{
              backgroundColor: "#222222",
              border: "1px solid #3C3D43",
              fontFamily: "Lato",
              color: "white",
              textAlign: "center",
              justifyContent: "center",
              padding: "5px",
              margin: "2px 5px",
              borderRadius: "5px",
              textTransform: "uppercase",
              width: "98%",
              transition: "500ms",
            }}
            className="btn-onhover"
            onClick={() => {
              setAnchorOnFromAmount(false);
              getTestBusd();
            }}
            disabled={disabled}
          >
            {disabled
              ? `⏳ TESTNET USDT AVAILABLE IN ${formatTime(timeRemaining)} ⏳`
              : claimableText
              ? `💰 GET ${claimableText}K TESTNET USDT 💰`
              : `💰 GET 10K TESTNET USDT 💰`}
          </button>
        </>
      )}

      {/* <div className="Exchange-swap-wallet-box App-box">
        {active && <div className="Exchange-swap-account" >
        </div>}
      </div> */}
      <div className="Exchange-swap-box-inner App-box-highlight">
        <div>
          <Tab
            icons={SWAP_ICONS}
            options={SWAP_OPTIONS}
            optionLabels={SWAP_LABELS}
            option={swapOption}
            onChange={onSwapOptionChange}
            className="Exchange-swap-option-tabs buttons"
          />
          {flagOrdersEnabled && (
            <Tab
              options={orderOptions}
              optionLabels={orderOptionLabels}
              className="Exchange-swap-order-type-tabs especial-buttons"
              userBusdBalance
              type="inline"
              option={orderOption}
              onChange={onOrderOptionChange}
            />
          )}
        </div>

        {showFromAndToSection && (
          <React.Fragment>
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {toUsdMax && <div className="Exchange-swap-usd">{getToLabel()}</div>}
                  {!toUsdMax && getToLabel()}
                </div>
                {toBalance && isSwap && (
                  <div className="muted align-right">
                    <Trans>Balance</Trans>: {formatAmount(toBalance, DECIMALS, 4, true)}
                  </div>
                )}
                {/* {(isLong || isShort) && hasLeverageOption && (
                  <div className="muted align-right">
                    <Trans>Leverage</Trans>: {parseFloat(leverageOption).toFixed(2)}x
                  </div>
                )} */}
                <div className="muted align-right">
                  {(() => {
                    if (true) {
                      return (
                        <div
                          style={{ display: "flex", height: "20px", alignItems: "center", justifyContent: "center" }}
                        >
                          <p style={{ marginTop: "10px", color: "#DEDEDE" }}>
                            {userBusdBalance === "..." ? (
                              <p style={{ marginBottom: "0px" }} onClick={() => setWalletModalVisible(true)}>
                                Connect Wallet
                              </p>
                            ) : (
                              userBusdBalance + " USDT"
                            )}
                          </p>
                          <button
                            id="boton-swap"
                            style={{
                              color: "white",
                              border: "none",
                              borderRadius: "3px",
                              padding: "2px 5px",
                              marginLeft: "5px",
                              color: "#616161",
                            }}
                            onClick={() => {
                              setAnchorOnFromAmount(false);
                              setToValue(userBusdBalance);
                            }}
                          >
                            Max
                          </button>
                        </div>
                      );
                    }
                    // else if (userBusdBalance <= 100) {
                    //   return (
                    //     <div>
                    //       {userBusdBalance} <Trans>BUSD</Trans>
                    //       <button
                    //         id="boton-swap"
                    //         style={{ color: "white", border: "none", borderRadius: "5px", padding: "2px 5px" }}
                    //         onClick={() => {
                    //           setAnchorOnFromAmount(false);
                    //           getTestBusd();
                    //         }}
                    //       >
                    //         Get test BUSD
                    //       </button>
                    //     </div>
                    //   );
                    // }
                  })()}
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    type="number"
                    min="10"
                    max="1000"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    style={{ textAlign: "right", width: "50%", marginRight: "5px" }}
                    id="input-swapbox-right"
                    value={toValue}
                    defaultValue={toValue}
                    onChange={onToValueChange}
                    onMouseOut={handleMouseOut}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", textAlign: "center", padding: "0px 3px" }}>
                  <Trans>USDT</Trans>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {showTriggerRatioSection && (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <Trans>Price</Trans>
              </div>
              {fromTokenInfo && toTokenInfo && (
                <div
                  className="muted align-right clickable"
                  onClick={() => {
                    setTriggerRatioValue(
                      formatAmountFree(
                        getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted),
                        USD_DECIMALS,
                        10
                      )
                    );
                  }}
                >
                  {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 4)}
                </div>
              )}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input small"
                  value={triggerRatioValue}
                  onChange={onTriggerRatioChange}
                />
              </div>
              {(() => {
                if (!toTokenInfo) return;
                if (!fromTokenInfo) return;
                const [tokenA, tokenB] = triggerRatioInverted
                  ? [toTokenInfo, fromTokenInfo]
                  : [fromTokenInfo, toTokenInfo];
                return (
                  <div className="PositionEditor-token-symbol">
                    {tokenA.symbol}&nbsp;per&nbsp;{tokenB.symbol}
                  </div>
                );
              })()}
            </div>
          </div>
        )}
        {/* {showTriggerPriceSection && (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <Trans>Price</Trans>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => {
                  setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2));
                }}
              >
                <Trans>Mark: {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPriceValue}
                  onChange={onTriggerPriceChange}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
        )} */}
        {isSwap && (
          <div className="Exchange-swap-box-info">
            <ExchangeInfoRow label={t`Fees`}>
              <div>
                {!fees && "-"}
                {fees && (
                  <div>
                    {formatAmount(feeBps, 2, 2, false)}%&nbsp; ({formatAmount(fees, fromToken.decimals, 4, true)}{" "}
                    {fromToken.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                  </div>
                )}
              </div>
            </ExchangeInfoRow>
          </div>
        )}
        {(isLong || isShort) && !isStopOrder && (
          <div className="Exchange-leverage-box">
            <div
              className="Exchange-leverage-slider-settings"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className="muted">
                Leverage slider
                <span style={{ color: "#148a63" }}> ({leverageOption}x)</span>
              </span>
              <span>
                {` (${Object.values(leverageMarks)[0]} - ${
                  Object.values(leverageMarks)[Object.keys(leverageMarks).length - 1]
                }) `}
              </span>
            </div>
            {isLeverageSliderEnabled && (
              <div
                className={cx("Exchange-leverage-slider", "App-slider", {
                  positive: isLong,
                  negative: isShort,
                })}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                <Slider
                  min={minLeverage}
                  max={maxLeverage}
                  step={1}
                  marks={leverageMarks}
                  handle={leverageSliderHandle}
                  onChange={(value) => setLeverageOption(value)}
                  value={leverageOption}
                  defaultValue={leverageOption}
                  onBlur={handleMouseOut}
                />
              </div>
            )}

            <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", width: "100%" }}>
              <div id="priceTag" className="Exchange-info-label" style={{ width: "100%" }}>
                <div className="Exchange-info-label">
                  <Trans>Price</Trans>
                </div>
                {(orderOption == MARKET || orderOption == LIMIT) && (
                  <input
                    id="PersonalizedTokenPrice"
                    onMouseOut={handleMouseOut}
                    onMouseDown={disallowChangePersonalizedTokenPrice}
                    onMouseUp={handleMouseUp}
                    type="number"
                    min="1"
                    max="10000"
                    placeholder="0.0"
                    className={`Exchange-swap-input ${!marketReadOnly ? "functional" : "readonly"}`}
                    defaultValue={personalizedTokenPrice || ""}
                    value={personalizedTokenPrice || ""}
                    readOnly={marketReadOnly}
                    onChange={(e) => manuallyPersonalizedTokenPrice(e.target.value)}
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", width: "100%" }}>
                <div
                  id="priceTag"
                  className="Exchange-info-label"
                  style={{ display: "flex", justifyContent: "space-between", alignContent: "center", width: "100%" }}
                >
                  <div className="Exchange-info-label" style={{ textAlign: "right", width: "50%" }}>
                    <Trans>Slippage</Trans>
                  </div>
                  <input
                    id="slippageP"
                    type="number"
                    min="0.01"
                    max="1000"
                    placeholder="0.0"
                    className={`Exchange-swap-input ${marketReadOnly ? "functional" : "readonly"}`}
                    readOnly={!marketReadOnly}
                    value={marketReadOnly ? slippageAmount : 0}
                    defaultValue={slippageAmount}
                    onMouseOut={handleMouseOut}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onChange={(e) => setSlippageAmount(e.target.value)}
                  />
                  {/* {hasExistingPosition && toAmount && toAmount.gt(0) && (
                  <div className="inline-block muted">
                    ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                    <BsArrowRight className="transition-arrow" />
                  </div>
                )}
                {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                {!nextAveragePrice && `-`} */}
                </div>
              </div>
            </div>
            <div className="Exchange-info-row" id="info-label-div">
              <div className="Exchange-info-label" id="info-label">
                Stop loss <span style={{ color: "#E23E3E" }}>({stopLossText})</span>
              </div>
            </div>
            <div id="stopLossCounter" className="box-select TradingPanel_boxSelect__zxO_S">
              <div
                onClick={() => {
                  setActiveClassNone("active");
                  setActiveClass10("");
                  setActiveClass25("");
                  setActiveClass50("");
                  setActiveClass75("");
                  setActiveClassStopLoss("box-select-input");
                  setManualStopLoss(false);
                  setStopLoss(0);
                  localStorage.setItem("StopLoss", 0);
                }}
                className={activeClassNone}
              >
                None
              </div>

              <div
                onClick={() => {
                  setActiveClassNone("");
                  setActiveClass10("active");
                  setActiveClass25("");
                  setActiveClass50("");
                  setActiveClass75("");
                  setActiveClassStopLoss("box-select-input");
                  setManualStopLoss(false);
                  setStopLoss(10);
                  localStorage.setItem("StopLoss", 10);
                }}
                className={activeClass10}
              >
                -10%
              </div>

              <div
                onClick={() => {
                  setActiveClassNone("");
                  setActiveClass10("");
                  setActiveClass25("active");
                  setActiveClass50("");
                  setActiveClass75("");
                  setActiveClassStopLoss("box-select-input");
                  setManualStopLoss(false);
                  setStopLoss(25);
                  localStorage.setItem("StopLoss", 25);
                }}
                className={activeClass25}
              >
                -25%
              </div>

              <div
                onClick={() => {
                  setActiveClassNone("");
                  setActiveClass10("");
                  setActiveClass25("");
                  setActiveClass50("active");
                  setActiveClass75("");
                  setActiveClassStopLoss("box-select-input");
                  setManualStopLoss(false);
                  setStopLoss(50);
                  localStorage.setItem("StopLoss", 50);
                }}
                className={activeClass50}
              >
                -50%
              </div>

              <div
                onClick={() => {
                  setActiveClassNone("");
                  setActiveClass10("");
                  setActiveClass25("");
                  setActiveClass50("");
                  setActiveClass75("active");
                  setActiveClassStopLoss("box-select-input");
                  setManualStopLoss(false);
                  setStopLoss(75);
                  localStorage.setItem("StopLoss", 75);
                }}
                className={activeClass75}
              >
                -75%
              </div>

              <input
                id="stopLossBox"
                style={{ width: "25%" }}
                onFocus={() => {
                  setActiveClassStopLoss("box-select-input");
                }}
                type="numbrer"
                className={activeClassStopLoss}
                placeholder="PRICE"
                autoComplete="off"
                defaultValue={stopLossText}
                value={stopLossText}
                onChange={(e) => setCustomStopLoss(e.target.value)}
              />
            </div>

            <div className="Exchange-info-row m-top-2" id="info-label-div">
              <div className="Exchange-info-label text-right" id="info-label">
                {" "}
                Take profit <span style={{ color: "#148A63" }}>({takeProfitText})</span>
              </div>
            </div>
            <div id="takeProfitCounter" className="box-select TradingPanel_boxSelect__zxO_S">
              <div
                onClick={() => {
                  setActiveClassPlus25("active");
                  setActiveClassPlus50("");
                  setActiveClassPlus100("");
                  setActiveClassPlus300("");
                  setActiveactClassPlusMax("");
                  setActiveClassTakeProfit("box-select-input");
                  setManualTakeProfit(false);
                  setTakeProfit(25);
                  localStorage.setItem("TakeProfit", 25);
                }}
                className={activeClassPlus25}
              >
                25%
              </div>

              <div
                onClick={() => {
                  setActiveClassPlus25("");
                  setActiveClassPlus50("active");
                  setActiveClassPlus100("");
                  setActiveClassPlus300("");
                  setActiveactClassPlusMax("");
                  setActiveClassTakeProfit("box-select-input");
                  setManualTakeProfit(false);
                  setTakeProfit(50);
                  localStorage.setItem("TakeProfit", 50);
                }}
                className={activeClassPlus50}
              >
                50%
              </div>

              <div
                onClick={() => {
                  setActiveClassPlus25("");
                  setActiveClassPlus50("");
                  setActiveClassPlus100("active");
                  setActiveClassPlus300("");
                  setActiveactClassPlusMax("");
                  setActiveClassTakeProfit("box-select-input");
                  setManualTakeProfit(false);
                  setTakeProfit(100);
                  localStorage.setItem("TakeProfit", 100);
                }}
                className={activeClassPlus100}
              >
                100%
              </div>

              <div
                onClick={() => {
                  setActiveClassPlus25("");
                  setActiveClassPlus50("");
                  setActiveClassPlus100("");
                  setActiveClassPlus300("active");
                  setActiveactClassPlusMax("");
                  setActiveClassTakeProfit("box-select-input");
                  setManualTakeProfit(false);
                  setTakeProfit(300);
                  localStorage.setItem("TakeProfit", 300);
                }}
                className={activeClassPlus300}
              >
                300%
              </div>

              <div
                onClick={() => {
                  setActiveClassPlus25("");
                  setActiveClassPlus50("");
                  setActiveClassPlus100("");
                  setActiveClassPlus300("");
                  setActiveactClassPlusMax("active");
                  setActiveClassTakeProfit("box-select-input");
                  setManualTakeProfit(false);
                  setTakeProfit(700);
                  localStorage.setItem("TakeProfit", 700);
                }}
                className={activeClassPlusMax}
              >
                700%
              </div>

              <input
                id="takeProfitBox"
                onFocus={() => {
                  setActiveClassTakeProfit("box-select-input");
                }}
                type="number"
                className={activeClassTakeProfit}
                placeholder="PRICE"
                autoComplete="off"
                defaultValue={takeProfitText}
                value={takeProfitText}
                onChange={(e) => setCustomTakeProfit(e.target.value)}
              />
            </div>
          </div>
        )}
        {isStopOrder && (
          <div className="Exchange-swap-section Exchange-trigger-order-info">
            <Trans>
              Take-profit and stop-loss orders can be set after opening a position. <br />
              <br />
              There will be a "Close" button on each position row, clicking this will display the option to set trigger
              orders. <br />
              <br />
              For screenshots and more information, please see the{" "}
              <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#stop-loss-take-profit-orders">docs</ExternalLink>
              .
            </Trans>
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button
            id="boton-swap-end"
            style={{
              width: "100%",
              border: "none",
              color: "white",
              borderRadius: "5px",
              padding: "15px 0px",
              backgroundColor: "#148A63",
            }}
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </div>
      {isSwap && (
        <div className="Exchange-swap-market-box App-box App-box-border">
          <div className="Exchange-swap-market-box-title">
            <Trans>Swap</Trans>
          </div>
          <div className="App-card-divider"></div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              {fromToken.symbol}
              <Trans> Price</Trans>
            </div>
            <div className="align-right">
              ${fromTokenInfo && formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)}
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              {toToken.symbol} <Trans> Price</Trans>
            </div>
            <div className="align-right">
              ${toTokenInfo && formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)}
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Trans>Available Liquidity</Trans>
            </div>

            <div className="align-right al-swap">
              <Tooltip
                handle={`$${formatAmount(maxSwapAmountUsd, USD_DECIMALS, 2, true)}`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <div>
                      <StatsTooltipRow
                        label={t`Max ` + `${fromTokenInfo.symbol}` + t` in`}
                        value={[
                          `${formatAmount(maxFromTokenIn, fromTokenInfo.decimals, 0, true)} ${fromTokenInfo.symbol}`,
                          `($${formatAmount(maxFromTokenInUSD, USD_DECIMALS, 0, true)})`,
                        ]}
                      />
                      <StatsTooltipRow
                        label={t`Max ` + `${toTokenInfo.symbol}` + `out`}
                        value={[
                          `${formatAmount(maxToTokenOut, toTokenInfo.decimals, 0, true)} ${toTokenInfo.symbol}`,
                          `($${formatAmount(maxToTokenOutUSD, USD_DECIMALS, 0, true)})`,
                        ]}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          {!isMarketOrder && (
            <ExchangeInfoRow label={t`Price`}>
              {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromToken, toToken)}
            </ExchangeInfoRow>
          )}
        </div>
      )}
      {(isLong || isShort) && (
        <div className="Exchange-swap-market-box App-box App-box-border" style={{ marginTop: "-5px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            <div className="Exchange-swap-market-box-title">{ourSelectedToken.symbol}</div>
            <div className="align-right">{getPrimaryText()}</div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px" }}>
            <div className="Exchange-swap-market-box-title">Execution Price</div>
            <div className="align-right">{priceIncludingSpread}</div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "-10px" }}>
            <div className="Exchange-swap-market-box-title">Spread</div>
            <div className="align-right">
              <p style={{ color: getSpreadColor(spreadP.toFixed(2)) }}>{spreadP.toFixed(2)}%</p>
              {/* <p>{spreadP.toFixed(2)}%</p> */}
            </div>
          </div>

          <div className="Exchange-info-row">
            <div className="Exchange-info-label" style={{ borderBottom: "1px dashed #DEDEDE", width: "104px" }}>
              <Tooltip
                handle={`Position size`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <div style={{ color: "#999" }}>
                      <Trans>Collateral * Leverage = Position size</Trans>
                    </div>
                  );
                }}
              />
            </div>
            <div className="align-right">
              {toAmount && leverage && <div>{positionSize.toFixed(2)}$</div>}
              {(!toAmount || !leverage) && <div>0</div>}
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label" style={{ borderBottom: "1px dashed #DEDEDE", width: "73px" }}>
              <Tooltip
                handle={`Liq. Price`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <div style={{ zIndex: "900" }}>
                      <Trans>If this price is reached, your position will be closed and your collateral lost.</Trans>
                    </div>
                  );
                }}
              />
            </div>
            <div className="align-right">
              {(() => {
                if (isLong) {
                  return (
                    <div>{formatDecimals(personalizedTokenPrice - personalizedTokenPrice / (leverage / 10000))}</div>
                  );
                } else if (!isLong) {
                  return (
                    <div>{formatDecimals(personalizedTokenPrice + personalizedTokenPrice / (leverage / 10000))}</div>
                  );
                }
              })()}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px", marginTop: "10px" }}>
            <div className="Exchange-swap-market-box-title"></div>
            <div className="align-right">?</div>
          </div>
        </div>
      )}
      <NoLiquidityErrorModal
        chainId={chainId}
        fromToken={fromToken}
        toToken={toToken}
        shortCollateralToken={shortCollateralToken}
        isLong={isLong}
        isShort={isShort}
        modalError={modalError}
        setModalError={setModalError}
      />
      {renderOrdersToa()}
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isSwap={isSwap}
          isLong={isLong}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          isShort={isShort}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          hasExistingPosition={hasExistingPosition}
          shortCollateralAddress={shortCollateralAddress}
          shortCollateralToken={shortCollateralToken}
          leverage={leverage}
          existingPosition={existingPosition}
          existingLiquidationPrice={existingLiquidationPrice}
          displayLiquidationPrice={displayLiquidationPrice}
          nextAveragePrice={nextAveragePrice}
          triggerPriceUsd={triggerPriceUsd}
          triggerRatio={triggerRatio}
          fees={fees}
          feesUsd={feesUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          collateralTokenAddress={collateralTokenAddress}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        />
      )}
    </div>
  );
}
